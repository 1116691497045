<template>
  <div style="background: #1D2700 ; padding-top: 60px;" class="zhitui">
    <div style="display: flex; align-items: center;margin-left: 20px;">
      <topbutton router="snowguan" style="margin-right: 20px;color:#fff"></topbutton>
    </div>
    <div class="lc">
      <div class="lc-img">
        <img src="@/assets/img/zhitui1.png" width="37px" height="auto" style="flex:1;">
        <img src="@/assets/img/zhitui4.png" width="42px" height="1px" style="flex:1;">
        <img src="@/assets/img/zhitui2.png" width="41px" height="auto" style="flex:1;">
        <img src="@/assets/img/zhitui4.png" width="42px" height="1px" style="flex:1;">
        <img src="@/assets/img/zhitui3.png" width="40px" height="auto" style="flex:1;">
      </div>
      <div class="lc-text">
        <span style="flex:1;">{{ $t('lang.邀请好友') }}</span>
        <span style="flex:1;">{{ $t('lang.好友参与协议') }}</span>
        <span style="flex:1;">{{ $t('lang.获得分享激励') }}</span>
      </div>
    </div>
    <div class="line"></div>
    <div class="fx">
      <span class="fx-title">{{ $t('lang.我的分享') }}</span>
      <div class="fx-list" v-if="fxList.length > 0">
        <div class="list-title item">
          <span>{{ $t('lang.地址') }}</span>
          <span>{{ $t('lang.状态') }}</span>
        </div>
        <div v-for="(item, index) in fxList" :key="index" class="list-content item">
          <span>{{ item.address }}</span>
          <span :style="{ color: item.is_valid == '0' ? '#999999' : '#FFFFFF' }">{{ item.is_valid ==
            '0' ?$t('lang.普通用户'):$t('lang.有效用户')}}</span>
        </div>
      </div>
      <div class="fx-list" v-else style="display: flex;flex-direction: column; align-items: center;">
        <img src="@/assets/img/no_jiangping.png" width="71px" height="auto">
        <span style="color: rgba(108, 141, 1, 1); font-size: 12px;">{{ $t('lang.暂无信息') }}~</span>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ipo_api } from '@/api/index';

import topbutton from '@/components/topbutton.vue';
export default {
  name: '',
  components: {
    topbutton
  },
  data() {
    return {
      fxList: [],

    }
  },
  computed: {
    ...mapState(['account', 'lang']),
  },
  created() {
    this.init()
  },
  watch: {
    account() {
      //获取账号
      this.init();
    },
    lang() {
      this.init();
    }
  },
  methods: {
    init() {
      if (this.account) {
        Promise.all([
          this.ipouserchildrenlist(),
        ])
      }

    },
    ipouserchildrenlist() {
      ipo_api.ipouserchildrenlist(this.account).then(res => {
        if (res.code == 200) {
          this.fxList = res.data || []
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
.zhitui {
  background-image: url('../../assets/img/jibie_bg.png');
  background-size: 100% auto;
  /* 保持宽度自适应，高度自动调整 */
  background-position: top;
  background-repeat: no-repeat;
  /* 防止背景图重复 */
  overflow: hidden;
  /* 隐藏多余的部分 */
  min-height: 100vh;
}

.s_title {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  font-family: 'PingFang';
}

.lc {
  display: flex;
  flex-direction: column;
  margin: 20px 0;

  .lc-img {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    margin: 0 23px;
    padding: 0 20px;
    gap: 20px;
  }

  .lc-text {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    margin: 10px 20px;
    gap: 20px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 21px;
    color: rgba(255, 255, 255, 1);
  }
}

.line {
  height: 1px;
  width: 100%;
  background: #fff;
  opacity: 0.2;
}

.fx {
  margin: 20px;
  text-align: center;

  .fx-title {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 27px;
    color: rgba(255, 255, 255, 1);
    text-decoration: underline;
    /* 增加下划线 */
    text-decoration-thickness: 3px;
    /* 控制下划线的粗细 */
    text-decoration-color: #b5e900;
    /* 设置下划线的颜色 */
    text-underline-offset: 1px;
    /* 调整下划线与文字的距离 */
  }

  .fx-list {
    border-radius: 16px;
    background: #3c5001;
    border: 1px solid #242834;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 17px 20px;
    margin-top: 15px;

    .list-title {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 21px;
      color: rgba(153, 153, 153, 1);
    }

    .list-content {
      font-size: 14px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
    }

    .item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
</style>