<template>
  <div style="background: #1D2700 ; padding-top: 60px;" class="jibie">
    <div style="display: flex; align-items: center;margin-left: 20px;">
      <topbutton router="snowguan" style="margin-right: 20px;color:#fff"></topbutton>
      <p class="s_title">{{ $t('lang.我的级别') }}</p>
    </div>
    <div class="title">

      <div class="title_bg" :style="{ background: `url(${jibieList[ComLevel].bg_Image})` }">
        <div class="left_text" :style="{ color: jibieList[ComLevel].textColor }">{{ $t(jibieList[ComLevel].name) }}
        </div>
        <div class="right_text">
          <span>{{ $t('lang.我的余额') }}</span>
          <span>{{ walletBanlaces.IpoBanlace }}</span>
        </div>
        <div class="t">{{ $t('lang.抽奖专区') }}</div>
      </div>

    </div>
    <div class="choujiang">
      <img src="@/assets/img/jibie2.png" alt="" width="100%">
      <div class="btn" @click="iporaffledraw">
        <div :class="holdInfo.raffle_status == '1' ? 'box_active' : 'box'">{{ $t('lang.抽奖') }}</div>
        <div class="box2">{{ $t('lang.抽奖') }}</div>
      </div>
      <div style="width: 100%;">
        <div class="jiangping">
          <div class="box">
            <span>{{ $t('lang.我的奖品') }}</span>
            <div class="line"></div>
            <div class="jp_list" v-if="raffleinfo.created_at != ''">
              <div class="item">
                <span>{{ raffleinfo.created_at }}</span>
                <span>{{ raffleinfo.reward_amount }} GGS </span>
              </div>
              <!-- <div class="item" v-else>
                <span>{{ raffleinfo.created_at }}</span>
                <span>{{ $t('lang.未中奖') }}</span>
              </div> -->
            </div>
            <div class="no_jp" v-else>
              <img src="@/assets/img/no_jiangping.png" width="71px" height="auto">
              <span style="margin-top:10px;">{{ $t('lang.暂无奖品') }}~</span>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%;">
        <div class="jiangping">
          <div class="box">
            <span>{{ $t('lang.我的邀请码') }}</span>
            <div class="inputBox" v-if="recommenderInfo.recommender == ''">
              <input type="text" v-model="invite_code" :placeholder="$t('lang.请填写推荐码')">
              <div class="inputBox_button" @click="postucbind" v-if="!bindLoading">{{$t('lang.绑定')}}</div>
              <van-loading color="rgba(181, 233, 0, 1)" style="margin-left: 20px;" v-else />
            </div>
            <div class="inputBox" v-else>
              <div class="inputBox_text" style="text-align: left;">
                {{ recommenderInfo.invite_code }}
              </div>
              <div class="CopyText" style="width: 24px; height: 24px; margin-left: 21px;" :data-clipboard-text="recommenderInfo.invite_code" @click="CopyText" >
                <img style="width: 100%; height: 100%;" src="@/assets/img/copy_yellow.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="role">
          <div class="role_box">
            <div style="display:flex;flex-direction:row;align-items: end;gap:5px;">
              <img src="@/assets/img/choujiang2.png" width="50%" style="margin-left: 10px;">
              <div class="role-btn" @click="look">{{ $t('lang.查看') }}</div>
            </div>
          </div>
          <div class="role_box">
            <img src="@/assets/img/choujiang3.png" width="60%" style="margin-left: 30px;">
          </div>
        </div>
        <div class="card">
          <div class="card_box ">
            <div class="text">
              <span class="text1">{{ $t('lang.我的直推') }}</span>
              <span class="text2">{{ levelInfo.dr_num || 0 }}</span>
            </div>
          </div>
          <div class="card_box">
            <div class="text">
              <span class="text1">{{ $t('lang.我的战队') }}</span>
              <span class="text2">{{ levelInfo.children_num || 0 }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dengji">
      <span>{{ $t('lang.社区等级说明') }}</span>
      <div class="dengji_list">
        <div class="list_title ">
          <span style="flex:2;">{{ $t('lang.等级') }}</span>
          <span style="flex:4;">{{ $t('lang.条件') }}</span>
          <span style="flex:2.5;">{{ $t('lang.权益') }}</span>
        </div>
        <div class="line"></div>
        <div :class="index == ComLevel ? 'list_box now_dengji' : 'list_box'" v-for="(item, index) in jibieList"
          :key="index">
          <span style="flex:2;">{{ $t(item.name) }}</span>
          <span style="flex:4;">{{ $t(item.tiaojian) }}</span>
          <span style="flex:2.5;">{{ $t(item.qunyi) }}</span>
        </div>
      </div>
      <div :class="ComisUpgrade ? 'dengji_btn2' : 'dengji_btn'" @click="payPopupOpenFunc" v-if="ComLevel != 4">
        <van-loading color="#3C5001" v-if="payLoading" />
        <span v-else> {{ $t('lang.升级') }}</span>

      </div>
      <div v-else style="height:100px;width:100%"></div>
    </div>
    <van-popup v-model="payPopup" round position="bottom">
      <div class="payPopup">
        <div class="payPopup_title">
          <div>{{ $t('lang.d190') }}</div>
          <div>
            <img src="@/assets/img/close_icon.png" alt="" @click="payPopup = false" />
          </div>
        </div>
        <div class="payPopup_box">
          <div class="payPopup_box_pay_amount">
            <span style="font-size: 48px; margin-right: 3px">{{ ComLevel == 0 ? Cconfig.pay_amount :
              levelUpgradeInfo.pay_amount }}</span>
            <span style="font-size: 24px">USDT</span>
          </div>
          <div class="payPopup_box_line" style="margin-top: 20px">
            <div class="payPopup_box_line_right" style=" border-bottom: 1px solid rgba(77, 102, 1, 1);">
              <div class="payPopup_box_line_right_left" style="margin-bottom: 10px;">
                <span>{{ $t('lang.选择支付') }}</span>
              </div>

            </div>
          </div>
          <div class="payPopup_box_line">
            <div class="payPopup_box_line_right">
              <div class="payPopup_box_line_right_left">
                <span style="margin-right: 5px;">{{ $t('lang.账户余额') }}</span>
                <span> : {{ walletBanlaces.AccountBanlace }}</span>
              </div>
              <div class="payPopup_box_line_right_check">
                <img src="@/assets/img/check_icon_green_true.png" alt="" />
                <!-- <img src="@/assets/img/check_icon_false.png" alt="" v-else /> -->
              </div>
            </div>
          </div>
          <div class="payPopup_box_line" :style="{ opacity: 0.4 }">
            <div class="payPopup_box_line_right">
              <div class="payPopup_box_line_right_left">
                <span style="margin-right: 5px;">{{ $t('lang.钱包余额') }}</span>
                <span> : {{ walletBanlaces.ChainBanlace }}</span>
              </div>
              <div class="payPopup_box_line_right_check">
                <!-- <img src="@/assets/img/check_icon_green_true.png" v-if="ComLevel == 0" alt="" /> -->
                <img src="@/assets/img/check_icon_false.png" alt="" />
              </div>
            </div>
          </div>
          <div class="payPopup_box_button" @click="isPayFun">
            {{ $t('lang.d196') }}

          </div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="paystatusPopup" round position="center">
      <div class="paystatusPopup">
        <div class="bgBox">
          <img src="@/assets/img/paystatusPopup_success.png" v-if="paystatusInfo.type == 'success'" alt="">
          <img src="@/assets/img/paystatusPopup_fail.png" v-else-if="paystatusInfo.type == 'fail'" alt="">
          <img src="@/assets/img/paystatusPopup_err.png" style="width: 83px; height: 75px;" v-else alt="">
          <span :class="paystatusInfo.type">{{ $t(paystatusInfo.text) }}</span>
          <div class="bgBox-button" @click="paystatusPopup = false">{{ $t('lang.确定') }}</div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="drawPopShow" round position="center" style="background: transparent;">
      <div style="width: 100%;">
        <img src="@/assets/imgsnowball/恭喜获得.gif" style="width: 375px;
height: 425.67px;" alt="">
      </div>
    </van-popup>
    <van-popup v-model="paystatusPopupMin" round position="center" style="background: transparent;">
      <div style="padding-top: 20px;">
        <div class="paystatusPopupMin">
          <div class="absTitle">{{ $t('lang.恭喜获得') }}</div>
          <div class="bgBox">
            <span>{{ raffleinfo.reward_amount }}</span>
          </div>
        </div>
      </div>

    </van-popup>
  </div>

</template>

<script>
import { mapState } from 'vuex';
import { ipo_api, web3_api, user } from '@/api/index';
import Clipboard from 'clipboard';
import topbutton from '@/components/topbutton.vue';
export default {
  name: '',
  components: {
    topbutton
  },
  data() {
    return {
      paystatusPopupMin: false,
      drawPopShow: false,
      raffleinfo: {
        created_at:''
      },
      payPopup: false,
      paystatusPopup: false,
      paystatusInfo: {
        type: 'success',
        text: 'lang.支付成功'
      },
      walletBanlaces: {
        AccountBanlace: 0,
        ChainBanlace: 0,
        IpoBanlace: 0
      },
      jibieList: [
        {
          "name": "lang.普通用户",
          "tiaojian": "lang.ONEDEX注册用户",
          "qunyi": "lang.无",
          bg_Image: require('@/assets/img/jibie_top0_false.png'),
          textColor: '#5562E9',
        },
        {
          "name": "lang.有效用户",
          "tiaojian": "lang.绑定领袖并完成1U验证",
          "qunyi": "lang.盲盒抽奖机会",
          bg_Image: require('@/assets/img/jibie_top0_true.png'),
          textColor: '#00ADC4',
        },
        {
          "name": "lang.初级社区",
          "tiaojian": "lang.分享有效5，战队有效50",
          "qunyi": "lang.15U可升级",
          bg_Image: require('@/assets/img/jibie_top1.png'),
          textColor: '#F4C000',
        },
        {
          "name": "lang.中级社区",
          "tiaojian": "lang.分享有效20，战队有效200",
          "qunyi": "lang.30U可升级",
          bg_Image: require('@/assets/img/jibie_top2.png'),
          textColor: '#28B85D',
        },
        {
          "name": "lang.高级社区",
          "tiaojian": "lang.分享有效40，战队有效400",
          "qunyi": "lang.60U可升级",
          bg_Image: require('@/assets/img/jibie_top3.png'),
          textColor: '#3C5001',
        }
      ],
      holdInfo: {
        raffle_status: ''
      },
      levelInfo: {
        team_level: 0
      },
      Cconfig: {},
      payLoading: false,
      levelUpgradeInfo: {},
      invite_code: '',
      recommenderInfo:{
        recommender:'',
        invite_code:''
      },
      bindLoading: false,
    }
  },
  computed: {
    ...mapState(['account', 'lang']),
    ComLevel() {
      if (this.levelInfo.team_level == 0) {
        return this.levelInfo.is_valid == '1' ? 1 : 0
      } else {
        return this.levelInfo.team_level + 1
      }
    },
    ComisUpgrade() {
      if (this.ComLevel == 0) {
        return true
      } else {
        return this.levelUpgradeInfo.upgrade
      }
    }
  },
  created() {
    this.init()
  },
  watch: {
    account() {
      //获取账号
      this.init();
    },
    lang() {
      this.init();
    }
  },
  methods: {
    payPopupOpenFunc() {

      if (!this.ComisUpgrade) return;
      if (this.payLoading) return;
      this.payPopup = true
    },
    init() {
      if (this.account) {
        Promise.all([
          this.ipouserlevelinfo(),
          this.ipoconfig(),
          this.iporaffleinfo(),
          this.iporaffleconfigs(),
          this.ipouserholdinfo(),
          this.ipouserwalletinfo(),
          this.getwalletBanlaces(),
          this.ipouserlevellist(),
          this.ipouserrecommender()
        ])
      }
    },
    ipouserrecommender(){
         ipo_api.ipouserrecommender(this.account).then(res=>{
            if(res.code == 200){
                 this.recommenderInfo = res.data
            }
         })
    },
    CopyText(){
      var clipboard = new Clipboard('.CopyText');
        clipboard.on('success', e => {
          this.$toast(this.$t('lang.swap300'));
          clipboard.destroy(); // 释放内存
        });
        clipboard.on('error', e => {
          this.$toast(this.$t('lang.swap301'));
          clipboard.destroy(); // 释放内存
        });
    },
    async postucbind() {
      if(this.invite_code == '') return this.$toast(this.$t('lang.请填写推荐码'));
      let sign;
      this.bindLoading = true
      try {
        sign = await web3_api.signMessage('IPO Bind Recommender', this.account)
      } catch (error) {
        this.paystatusInfo = {
          type: 'err',
          text: 'lang.签名失败'
        }
        this.bindLoading = false
        return;
      }
      let data = {
         invite_code: this.invite_code,
        signature:sign
       }
      ipo_api.ipouserbind(data, this.account).then(res => {
        if (res.code == 200) {
          this.init()
        }
        this.bindLoading = false
        this.$toast(res.msg)
      }).catch(err=>{
        this.bindLoading = false
      })
    },
    getwalletBanlaces() {
      web3_api.getERC20TokenBalance('0x55d398326f99059fF775485246999027B3197955', this.account).then(res => {
        this.walletBanlaces.ChainBanlace = res
      })
      ipo_api.walletinfobykey({ token_key: 'USDT' }, this.account).then(res => {
        this.walletBanlaces.AccountBanlace = res.data.usable
      })
    },
    ipouserwalletinfo() {
      ipo_api.ipouserwalletinfo(this.account).then(res => {
        if (res.code == 200) {
          this.walletBanlaces.IpoBanlace = res.data.usable
        }
      })
    },
    isPayFun() {
      this.payPopup = false
      if (this.ComLevel == 0) {
        switch (this.Cconfig.status) {
          case '1':
            this.ipouserjoin()
            break;
          case '0':
            this.$toast(this.$t('lang.不可购买'))
            break;
          case '2':
            this.$toast(this.$t('lang.未绑定上级'))
            break;
          case '3':
            this.$toast(this.$t('lang.已购买'))
            break;
          case '4':
            this.$toast(this.$t('lang.入账中'))
            break;
          default:
            break;
        }

      } else {
        this.ipouserupgrade()
      }
    },
    async ipouserjoin() {
      if (parseFloat(this.walletBanlaces.AccountBanlace) < this.Cconfig.pay_amount) {

        this.paystatusInfo = {
          type: 'fail',
          text: 'lang.余额不足'
        }
        this.paystatusPopup = true;
        return;
      }
      this.payLoading = true
      let sign;
      try {
        sign = await web3_api.signMessage('IPO Join', this.account)
      } catch (error) {
        this.paystatusInfo = {
          type: 'err',
          text: 'lang.签名失败'
        }
        this.paystatusPopup = true;
        this.payLoading = false
        return;
      }
      let data = {
        signature: sign,
      }
      ipo_api.ipouserjoin(data, this.account).then(res => {
        this.$toast(res.msg)
        if (res.code == 200) {
          this.paystatusInfo = {
            type: 'success',
            text: 'lang.升级成功'
          }
          this.paystatusPopup = true;
          this.init()
        } else {
          this.paystatusInfo = {
            type: 'fail',
            text: res.msg
          }
          this.paystatusPopup = true;
        }
        this.payLoading = false
      }).catch(err => {
        this.payLoading = false
        this.paystatusInfo = {
          type: 'err',
          text: 'lang.升级失败'
        }
        this.paystatusPopup = true;
      })
    },
    async ipouserupgrade() {
      if (parseFloat(this.walletBanlaces.AccountBanlace) < this.levelUpgradeInfo.pay_amount) {

        this.paystatusInfo = {
          type: 'fail',
          text: 'lang.余额不足'
        }
        this.paystatusPopup = true;
        return;
      }
      this.payLoading = true
      let sign;
      try {
        sign = await web3_api.signMessage('IPO Level Upgrade', this.account)
      } catch (error) {
        this.paystatusInfo = {
          type: 'err',
          text: 'lang.签名失败'
        }
        this.paystatusPopup = true;
        this.payLoading = false
        return;
      }
      let data = {
        signature: sign,
        level_id: this.levelUpgradeInfo.id
      }
      ipo_api.ipouserupgrade(data, this.account).then(res => {
        this.$toast(res.msg)
        if (res.code == 200) {
          this.paystatusInfo = {
            type: 'success',
            text: 'lang.升级成功'
          }
          this.paystatusPopup = true;
          this.init()
        } else {
          this.paystatusInfo = {
            type: 'fail',
            text: res.msg
          }
          this.paystatusPopup = true;
        }
        this.payLoading = false
      }).catch(err => {
        this.payLoading = false
        this.paystatusInfo = {
          type: 'err',
          text: 'lang.升级失败'
        }
        this.paystatusPopup = true;
      })
    },
    async iporaffledraw() {

      if (this.holdInfo.raffle_status != '1') return;
      let sign;
      try {
        sign = await web3_api.signMessage('IPO Raffle Draw', this.account)
      } catch (error) {
        this.$toast($t('lang.签名失败'))
        return;
      }
      let data = {
        signature: sign
      }
      ipo_api.iporaffledraw(data, this.account).then(res => {
        if (res.code == 200) {
          this.drawPopShow = true;
          this.init()
          let timer = setInterval(() => {
            this.drawPopShow = false;
            this.paystatusPopupMin = true
            let timer2 = setInterval(() => {
              this.paystatusPopupMin = false

              clearInterval(timer2);
            }, 2000);
            clearInterval(timer);
          }, 2000);
        } else {
          this.$toast(res.msg)
        }

      })
    },
    ipouserholdinfo() {
      ipo_api.ipouserholdinfo(this.account).then(res => {
        if (res.code == 200) {
          this.holdInfo = res.data
        }
      })
    },
    ipouserlevellist() {
      ipo_api.ipouserlevellist(this.account).then(res => {
        if (res.code == 200) {
          let list = res.data;
          let isUpgrade = false;
          list.forEach((element, index) => {
            if (element.upgrade) {
              isUpgrade = true;
              this.levelUpgradeInfo = element;
            }
          });
          if (!isUpgrade) {
            this.levelUpgradeInfo = {
              id: 0,
              upgrade: false,
              pay_amount: 0,
            }
          }
        }
      })
    },
    ipouserlevelinfo() {
      ipo_api.ipouserlevelinfo(this.account).then(res => {
        if (res.code == 200) {
          this.levelInfo = res.data.level_info

        }
      })
    },
    ipoconfig() {
      ipo_api.ipoconfig(this.account).then(res => {
        if (res.code == 200) {
          this.Cconfig = res.data
        }
      })
    },
    iporaffleinfo() {
      ipo_api.iporaffleinfo(this.account).then(res => {
        if (res.code == 200) {
          this.raffleinfo = res.data
        }
      })
    },
    iporaffleconfigs() {
      ipo_api.iporaffleconfigs(this.account).then(res => {
      })
    },
    look() {
      this.$router.push({ name: 'zhitui' });
    }
  },
}
</script>

<style lang="less" scoped>
.jibie {
  background-image: url('../../assets/img/jibie_bg.png');
  background-size: 100% auto;
  /* 保持宽度自适应，高度自动调整 */
  background-position: top;
  background-repeat: no-repeat;
  /* 防止背景图重复 */
  overflow: hidden;
  /* 隐藏多余的部分 */
}

.paystatusPopupMin {

  width: 160px;
  height: 123px;
  opacity: 1;
  border-radius: 16px;
  background: #B5E900;
  padding: 6px;

  border: 2px solid #000000;
  position: relative;

  .absTitle {
    position: absolute;
    left: 50%;
    top: 4%;
    transform: translate(-50%, -50%);
    width: 126.64px;
    height: 37px;
    background: url('~@/assets/img/absTitle_bg.png') no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    text-shadow:
      2px 2px 0px black,
      /* 上右 */
      -2px 2px 0px black,
      /* 下右 */
      2px -2px 0px black,
      /* 上左 */
      -2px -2px 0px black;
    /* 下左 */
  }

  .bgBox {
    width: 100%;
    height: 100%;
    background: url('~@/assets/img/paystatusPopup_bg_min.png') no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 27px;

    span {
      font-size: 36px;
      color: rgba(181, 233, 0, 1);
      font-weight: bold;
    }
  }
}

.paystatusPopup {
  width: 319px;
  height: 250.5px;
  opacity: 1;
  border-radius: 16px;
  background: #B5E900;
  padding: 6px;

  border: 2px solid #000000;

  .bgBox {
    width: 100%;
    height: 100%;
    background: url('~@/assets/img/paystatusPopup_bg.png') no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 27px;

    span {
      margin-top: 16px;
      margin-bottom: 25px;
      font-size: 14px;

    }

    .success {
      color: #B5E900;
    }

    .fail {
      color: #3FA0FF;
    }

    .err {
      color: #B5E900;
    }

    img {
      width: 122px;
      height: 97px;
    }

    .bgBox-button {
      flex-shrink: 0;
      width: 100%;
      height: 44px;
      opacity: 1;
      border-radius: 110px;
      background: #B5E900;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #000000;
      font-size: 16px;
      color: #192401;
      font-weight: bold;
    }
  }
}

.payPopup {
  width: 100%;
  background: rgba(60, 80, 1, 1);

  .payPopup_title {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 21px;
    color: rgba(181, 233, 0, 1);
    border-bottom: 1px solid rgba(108, 141, 1, 1);

    img {
      width: 13.44px;
      height: 13.44px;
    }
  }

  .payPopup_box {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .payPopup_box_button {
      margin-top: 36.4px;
      width: 335px;
      height: 48px;
      opacity: 1;
      border-radius: 110px;
      background: rgba(181, 233, 0, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 18px;
      color: rgba(25, 36, 1, 1);
      border: 1px solid rgba(0, 0, 0, 1);
    }

    .payPopup_box_pay_amount {
      margin-top: 12px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 36px;
      color: rgba(181, 233, 0, 1);
    }

    .payPopup_box_line {
      margin-top: 10px;
      width: 100%;
      display: flex;

      .payPopup_box_line_left {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }

      .payPopup_box_line_right {
        // padding-bottom: 13px;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;


        .payPopup_box_line_right_left {
          display: flex;
          text-align: left;
          align-items: center;
          font-size: 14px;
          color: rgba(181, 233, 0, 1);
          margin-bottom: 3px;
        }

        .payPopup_box_line_right_check {
          width: 14px;
          height: 14px;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}

.s_title {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  font-family: 'PingFang';
}

.title {
  margin-top: 10px;
  position: relative;

  .title_bg {
    width: 100%;
    height: 303px;
    position: relative;
    // background: url('~@/assets/img/jibie_top0_true.png') no-repeat;
    background-size: contain !important;

    .left_text {
      transform: rotate(-10deg);
      position: absolute;
      top: 60px;
      left: 11%;
      font-size: 18px;
      font-weight: 700;
    }

    .right_text {
      transform: rotate(25deg);
      color: rgba(212, 161, 0, 1);
      font-size: 12px;
      position: absolute;
      top: 65px;
      right: 10%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .t {
      position: absolute;
      bottom: 25px;
      /* 让 .t 紧贴底部 */
      left: 50%;
      /* 将 .t 的左边对齐到父容器的中间 */
      transform: translateX(-50%);
      /* 向左移动自身宽度的一半，居中 */
      font-size: 24px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
    }
  }


}

.choujiang {
  width: 100%;
  overflow: hidden;
  background-image: url('../../assets/img/choujiang_bg.png');
  background-size: 100% auto;
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .btn {
    font-size: 18px;
    font-weight: 700;
    color: rgba(84, 138, 34, 1);
    text-align: center;
    display: inline-block;
    /* 让按钮宽度根据内容自适应 */
    position: relative;
    margin-top: -20px;
    width: 100%;
    height: 50px;

    .box {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 69px;
      background: #314d15;
      border: 2px solid #000000;
      padding: 10px 70px;
      /* 添加内边距以使按钮有适当的宽度 */
      text-align: center;
      z-index: 10;
      /* 确保在 .box2 之上 */
    }

    .box_active {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 69px;
      background: rgba(181, 233, 0, 1);
      color: rgba(25, 36, 1, 1);
      border: 2px solid rgba(0, 0, 0, 1);
      padding: 10px 70px;
      /* 添加内边距以使按钮有适当的宽度 */
      text-align: center;
      z-index: 10;
      /* 确保在 .box2 之上 */
    }

    .box2 {
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 69px;
      background: #356705;
      border: 2px solid #000000;
      padding: 10px 70px;
      /* 添加内边距以使按钮有适当的宽度 */
      text-align: center;
      z-index: 1;
      /* 确保在 .box 之下 */
    }
  }

  .jiangping {
    border-radius: 16px;
    background: #b5e900;
    border: 2px solid #000000;
    padding: 9px 7px;
    margin: 20px;

    .box {
      background-image: url('../../assets/img/jiangping_bg.png');
      /* 不规则背景图片 */
      background-size: 100% 100%;
      background-position: top;
      background-repeat: no-repeat;
      text-align: center;
      padding: 12px;
      font-size: 14px;
      font-weight: 900;
      letter-spacing: 0px;
      line-height: 19.6px;
      color: rgba(255, 255, 255, 1);

      .inputBox {
        margin-top: 17px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .inputBox_button {
          margin-left: 21px;
          background: rgba(181, 233, 0, 1);
          color: rgba(29, 39, 0, 1);
          padding: 9px 10px;
          border-radius: 10px;
        }

        input,
        .inputBox_text {
          background: none;
          outline: none;
          border: none;
          color: rgba(181, 233, 0, 1);
          border-radius: 6px;
          padding: 9px 10px;
          flex: 1;
          border: 1px solid rgba(108, 141, 1, 1);
        }
      }

      .no_jp {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 14px;
        font-weight: 900;
        letter-spacing: 0px;
        line-height: 19.6px;
        color: rgba(108, 141, 1, 1);
      }

      .jp_list {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: 12px;
          font-weight: 900;
          letter-spacing: 0px;
          line-height: 16.8px;
          color: rgba(255, 255, 255, 1);
        }
      }
    }

    .line {
      width: 100%;
      /* 虚线宽度 */
      border-top: 1px dashed #b7e9007d;
      /* 虚线颜色和粗细 */
      margin: 11px 0;
      /* 上下间距 */
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;

    .role {
      display: flex;
      justify-content: space-between;
      align-items: end;
      z-index: 10;

      .role_box {
        text-align: start;
        flex: 1;
      }

      .role-btn {
        border-radius: 73px;
        background: #1d2700;
        padding: 0 10px;
        display: inline-block;
        font-size: 14px;
        font-weight: 700;
        color: rgba(255, 255, 255, 1);
        padding: 3px 12px;
        margin-bottom: 20px;
      }
    }

    .card {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      margin-top: -60px;
      z-index: 1;

      .card_box {
        position: relative;
        width: 200px;
        height: 130px;
        background-image: url('../../assets/img/box_bg.png');
        /* 不规则背景图片 */
        background-size: 100% auto;
        background-position: top;
        background-repeat: no-repeat;
        border-radius: 20px;
        /* 可根据需要调整圆角 */
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;
        text-align: end;
        padding: 0 8px;

        .text {
          position: absolute;
          bottom: 18px;
          left: 16px;
          display: flex;
          flex-direction: column;
          text-align: start;

          .text1 {
            font-size: 24px;
            font-weight: 600;
            color: rgba(29, 39, 0, 1);
          }

          .text2 {
            font-size: 16px;
            font-weight: 600;
            color: rgba(29, 39, 0, 1);
          }
        }
      }
    }
  }
}

.choujiang::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: inherit;
  background-size: inherit;
  background-position: inherit;
  background-repeat: inherit;
  transform: rotate(0deg);
  transform-origin: center center;
  clip-path: inset(30px 0 0 0);
  z-index: -1;
}

.dengji {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  margin: 0 20px;

  .dengji_list {
    border-radius: 16px;
    background: #3c5001;
    border: 2px solid #000000;
    margin: 11px 0;
    padding: 10px;

    .list_title {
      display: flex;
      text-align: start;
      gap: 10px;
      padding: 3px 10px;
      font-size: 14px;
      font-weight: 900;
      color: rgba(255, 255, 255, 1);
    }

    .list_box {
      display: flex;
      text-align: start;
      gap: 10px;
      padding: 7px 12px;
      margin-bottom: 5px;
      word-break: break-all;
      font-size: 11px;
      font-weight: 700;
      color: rgba(147, 191, 0, 1);
    }

    .now_dengji {
      border-radius: 6px;
      background: rgba(181, 233, 0, 1);
      border: 1px solid rgba(181, 233, 0, 1);
      color: rgba(29, 39, 0, 1);
    }
  }

  .line {
    width: 100%;
    /* 虚线宽度 */
    border-top: 1px dashed #b7e9007d;
    /* 虚线颜色和粗细 */
    margin: 11px 0;
    /* 上下间距 */
  }

  .dengji_btn {
    border-radius: 69px;
    background: #314d15;
    border: 2px solid #000000;
    font-size: 18px;
    font-weight: 700;
    color: rgba(84, 138, 34, 1);
    padding: 10px 0;
    margin: 26px 0 60px 0;
  }

  .dengji_btn2 {
    border-radius: 69px;
    background: #b5e900;
    border: 2px solid #000000;
    font-size: 18px;
    font-weight: 700;
    color: #192401;
    padding: 10px 0;
    margin: 26px 0 60px 0;
  }
}
</style>