import request from '../utils/request';
export default {
    miningwalletinfo(address) {
        return request.request({
            url: '/mining/wallet/info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },   
        })
    },
    miningwalletrecordlist(data,address) {
        return request.request({
            url: '/mining/wallet-record/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },  
            params:data 
        })
    },
    mininguserclaim(data,address) {
        return request.request({
            url: '/mining/user/claim',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },  
            data:data 
        })
    },
    mininguservalidhash(data,address) {
        return request.request({
            url: '/mining/user/valid-hash',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },  
            data:data 
        })
    },
    miningconfig(address) {
        return request.request({
            url: '/mining/config',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },   
        })
    },
    miningusertodayreward(address) {
        return request.request({
            url: '/mining/user/today-reward',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },   
        })
    },
    mininguserensurelist(data,address) {
        return request.request({
            url: '/mining/user/ensure-list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },   
            params:data
        })
    },
    
}