<template>
  <div class="earn">
    <div class="e_main"
         :style="`background: url(${ColorCheckData=='black'? bglist.image: bglist.image1}) no-repeat;`">
      <span class="m_title">{{$t('lang.h49')}}</span>
      <span class="m_text">{{ $t('lang.swap367') }}</span>
      <div class="m_button">
        <span class="b_title">{{ $t('lang.h121') }}</span>
        <span class="b_text">{{ $t('lang.swap369') }}</span>
      </div>
    </div>
    <van-tabs v-model="active"
              animated
              class="van_tabs"
              background="var( --box-bg-color)">
      <van-tab :title="`${$t('lang.d42')}`"
               background="var( --box-bg-color)">
        <div class="i_lon">
          <div class="lon_list2">
            <div class="list_top">
              <img src="@/assets/img/zhaomu.png"
                   style="margin-right: 20px;"
                   alt="">
              <div class="top_right">
                <span class="title">GGSlayer</span>
                <span class="text">{{ $t('lang.成员招募') }}</span>
                <div class="btn zhaomu" @click="Lottery">{{ $t('lang.招募进行中') }}</div>
              </div>
            </div>
            <div class="line"></div>
            <div class="content">
              <span>{{ $t('lang.GGslayer是Web3') }}</span>
            </div>
          </div>
          <!-- <div class="meiyou">
            <img src="@/assets/img/zanwu.png"
                 alt=""
                 v-if="ColorCheckData == 'black'">
            <img src="@/assets/img/zanwu1.png"
                 alt=""
                 v-else>
            <span>{{$t('lang.h50')}}</span>
          </div> -->
          <!-- <div class="lon_list"
               v-for="item in datalist">
            <div class="lon_line1">
              <div class="line1_left">
                <img src="@/assets/imgsnowball/chuangshi.png"
                     style="margin-right: 20px;"
                     alt="">
                <span>Water</span>
              </div>
              <div class="line1_right">
                <div class="quare"></div>
                <div>已完成</div>
              </div>
            </div>
            <div class="lon_line2">Creation Plan</div>
            <div class="lon_line3">
              <span style="margin-right: 5px;">Water</span>
              <span>Bull</span>
            </div>
            <div class="lon_line4">
              <van-slider active-color="rgba(118, 173, 252, 1)"
                          bar-height="8"
                          v-model="sipper"
                          @change="onChange">
                <template #button>
                  <div></div>
                </template>
              </van-slider>
              <div style="margin-top: 10px;">50%</div>
            </div>
            <div class="lon_line5"
                 style="margin-bottom: 30px;">
              <div class="line5_icon">-</div>
              <div class="line5_num">10000</div>
              <div class="line5_icon">+</div>
            </div>
            <div class="lon_line6"
                 style="margin-bottom: 10px;">参与</div>
            <div class="lon_line7">
              <div class="line7_left">我已参与</div>
              <div class="line7_left">10000 Water</div>
            </div>
            <div class="lon_line7"
                 style="margin-bottom: 26px;">
              <div class="line7_left">我已分享</div>
              <div class="line7_left">10000 Water</div>
            </div>
            <div class="lon_line8">
              <div class="line8_left">我可领取：15000 Water LP</div>
              <div class="line8_right">领取</div>
            </div>
            <div class="lon_line8">
              <div class="line8_left">我可领取：15000 Bull LP</div>
              <div class="line8_right">领取</div>
            </div>
            <div class="lon_line9"
                 @click="ishiddenFun(item)">
              <div style="margin-right: 10px;">隐藏</div>
              <van-icon name="arrow-down" />
            </div>
            <div class="lon_line10"
                 :style="item.ishidden ?'height: 250px;':'height: 0px'">
              <div class="line10_text">
                HEP is the GameFi ecological sub-coin issued byCherryswap. HEP is an incentive token for players toparticipate in game competition, mainly used tobreed new pets in the game.
              </div>
              <div class="lon_line7">
                <div class="line7_left">开始时间:</div>
                <div class="line7_right">00:00:00</div>
              </div>
              <div class="lon_line7">
                <div class="line7_left">参与方式::</div>
                <div class="line7_right">10K - 200K water</div>
              </div>
              <div class="lon_line7">
                <div class="line7_left">计划募集:</div>
                <div class="line7_right">50M - 100M Water</div>
              </div>
              <div class="lon_line7">
                <div class="line7_left">结束时间:</div>
                <div class="line7_right">20/11/2023</div>
              </div>
              <div class="lon_line7">
                <div class="line7_left">上线时间:</div>
                <div class="line7_right">00:00:00</div>
              </div>
            </div>
          </div> -->
        </div>
      </van-tab>
      <van-tab :title="`${$t('lang.d54')}`">
        <!-- <div class="i_lon">
          <div class="meiyou">
            <img src="@/assets/img/zanwu.png"
                 alt=""
                 v-if="ColorCheckData == 'black'">
            <img src="@/assets/img/zanwu1.png"
                 alt=""
                 v-else>
            <span>{{$t('lang.h50')}}</span>
          </div>
        </div> -->
        <div class="i_lon">
          <div class="lon_list2">
            <div class="list_top">
              <img src="@/assets/img/zhaomu.png"
                   style="margin-right: 20px;"
                   alt="">
              <div class="top_right">
                <span class="title">GGSlayer</span>
                <span class="text">{{ $t('lang.成员招募') }}</span>
                <div class="btn zhaomu" @click="Lottery">{{ $t('lang.招募进行中') }}</div>
              </div>
            </div>
            <div class="line"></div>
            <div class="content">
              <span>{{ $t('lang.GGslayer是Web3') }}</span>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab :title="`${$t('lang.swap370')}`">
        <div class="i_lon">
          <div class="meiyou">
            <img src="@/assets/img/zanwu.png"
                 alt=""
                 v-if="ColorCheckData == 'black'">
            <img src="@/assets/img/zanwu1.png"
                 alt=""
                 v-else>
            <span>{{$t('lang.h50')}}</span>
          </div>
        </div>
        <!-- <div class="i_lon">
          <div class="lon_list2">
            <div class="list_top">
              <img src="@/assets/img/zhaomu.png"
                   style="margin-right: 20px;"
                   alt="">
              <div class="top_right">
                <span class="title">GGSlayer</span>
                <span class="text">成员招募</span>
                <div class="btn kaoqi">即将开启</div>
              </div>
            </div>
            <div class="line"></div>
            <div class="content">
              <span>GGslayer是Web3领先的游戏发行平台，完善的技术兼容性，让平台可以为开发者和用户提供深度的服务和舒畅体验，同时区块链技术的应用可以更好的平衡用户、游戏、和发行平台之间的价值分配关系。</span>
            </div>
          </div>
        </div> -->
      </van-tab>
      <van-tab :title="`${$t('lang.swap359')}`">
        <div class="i_lon">
          <div class="meiyou">
            <img src="@/assets/img/zanwu.png"
                 alt=""
                 v-if="ColorCheckData == 'black'">
            <img src="@/assets/img/zanwu1.png"
                 alt=""
                 v-else>
            <span>{{$t('lang.h50')}}</span>
          </div>
        </div>
        <!-- <div class="i_lon">
          <div class="lon_list2">
            <div class="list_top">
              <img src="@/assets/img/zhaomu.png"
                   style="margin-right: 20px;"
                   alt="">
              <div class="top_right">
                <span class="title">GGSlayer</span>
                <span class="text">成员招募</span>
                <div class="btn jiesu">招募结束</div>
              </div>
            </div>
            <div class="line"></div>
            <div class="content">
              <span>GGslayer是Web3领先的游戏发行平台，完善的技术兼容性，让平台可以为开发者和用户提供深度的服务和舒畅体验，同时区块链技术的应用可以更好的平衡用户、游戏、和发行平台之间的价值分配关系。</span>
            </div>
          </div>
        </div> -->
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'earn',
  components: {

  },
  data () {
    return {
      sipper: 50,
      active: '',
      selectlist: [
        {
          name: "全部"
        },
        {
          name: "进行中"
        },
        {
          name: "即将到来"
        },
        {
          name: "已完成"
        }
      ],
      datalist: [
        { ishidden: true, }, { ishidden: false, }, { ishidden: false, }
      ],
      bglist: {
        image: require('@/assets/img/earnimg.png'),
        image1: require('@/assets/img/earnimg1.png'),
      },
    }
  },
  computed: {
    ...mapState(['account', 'lang', 'ColorCheckData']),
  },
  created () {

  },
  methods: {
    ishiddenFun (item) {
      item.ishidden = !item.ishidden
    },
    Lottery(){
      console.log('点击了');
      this.$router.push({ name: 'jibie' });

    }
  },
}
</script>

<style lang="less" scoped>
.earn {
  padding-bottom: 30px;
  background: var(--bg-color);
}
.e_main {
  text-align: center;
  width: 100%;
  height: 471px;

  background-size: contain !important;
  background-position: 50% 50% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .m_title {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 32.82px;
    color: var(--text-color);

    margin-top: 40px;
  }

  .m_text {
    margin-top: 10px;
    opacity: 0.6;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 18.75px;
    color: var(--text-color);
    text-align: center;
    width: 84%;
  }

  .m_button {
    padding: 10px;
    width: 336px;
    height: 60px;
    opacity: 1;
    border-radius: 124px;
    background: rgba(102, 180, 174, 1);
    position: absolute;
    bottom: 35px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    .b_title {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 18.75px;
      color: #fff;
      margin-bottom: 5px;
    }

    .b_text {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 16.41px;
      color: rgba(255, 255, 255, 0.6);
    }
  }
}

.van_tabs {
  border-radius: 30px 30px 0 0;
  overflow: hidden;
  margin-bottom: 50px;
}

/deep/.van-tabs__content {
  border: none !important;
}
/deep/.van-tabs__nav {
  background: var(--box-bg-color) !important;
  border: none !important;
}

/deep/.van-tabs__line {
  background: rgba(102, 180, 174, 1) !important;
  width: 20px;
  height: 2px;
  border: none !important;
}

/deep/.van-tab {
  border: none !important;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 21px;
  color: rgba(153, 153, 153, 1);
}

/deep/.van-tab--active {
  border: none !important;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 21px;
  color: var(--text-color);
}

.i_lon {
  border: none !important;
  background: var(--box-bg-color);
  width: 100%;
  padding: 20px;
  min-height: 400px;
  padding-bottom: 100px;
  // margin-top: 20px;

  position: relative;

  .lon_list2 {
    border-radius: 20px;
    border: 1px solid #dedede;
    padding: 30px 0px;
    margin-bottom: 20px;
    img {
      width: 119px;
      height: 119px;
    }
    .list_top {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
    }
    .top_right {
      display: flex;
      flex-direction: column;
      text-align: end;
      justify-content: space-between;
      .title {
        font-size: 24px;
        font-weight: 700;
        color: #66b4ae;
      }
      .text {
        font-size: 18px;
        font-weight: 400;
        color: rgba(133, 133, 133, 1);
      }
      .btn {
        border-radius: 10px;
        text-align: center;
        padding: 5px 20px;
        font-size: 16px;
        font-weight: 700;
        color: rgba(255, 255, 255, 1);
      }
      .kaoqi {
        background: #66b4ae;
      }
      .zhaomu {
        background: #a9d118;
      }
      .jiesu {
        background: #c9c9c9;
      }
    }
    .line {
      border-top: 1px solid #dedede80; /* 创建一条红色实线 */
      margin: 20px 0;
    }
    .content {
      padding: 0 20px;
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      color: rgba(51, 51, 51, 1);
      text-align: left;
      vertical-align: middle;
    }
  }
  .lon_list {
    margin-bottom: 26px;
    width: 100%;
    padding: 38px 19px;
    opacity: 1;
    border-radius: 16px;

    border: 1.5px solid rgba(201, 223, 255, 1);

    .lon_line1 {
      margin-bottom: 37px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .line1_left {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 21px;
        color: rgba(56, 56, 56, 1);

        img {
          width: 58px;
          height: 85px;
        }
      }

      .line1_right {
        width: 81px;
        height: 28px;
        opacity: 1;
        border-radius: 75px;

        border: 1px solid rgba(201, 223, 255, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 18.2px;
        color: rgba(0, 0, 0, 1);

        .quare {
          border-radius: 50%;
          width: 8px;
          height: 8px;
          opacity: 1;
          background: rgba(69, 131, 255, 1);
          margin-right: 8px;
        }
      }
    }

    .lon_line2 {
      margin-bottom: 2px;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 21px;
      color: rgba(102, 102, 102, 1);
    }

    .lon_line3 {
      margin-bottom: 37px;
      font-size: 34px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 21px;
      color: rgba(56, 56, 56, 1);
    }

    .lon_line4 {
      margin-bottom: 36px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 14px;
      color: rgba(153, 153, 153, 1);
    }

    .lon_line5 {
      width: 100%;
      padding: 5px 4px;
      height: 52px;
      opacity: 1;
      border-radius: 82px;
      background: rgba(240, 245, 254, 1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .line5_num {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 27px;
        color: rgba(0, 0, 0, 1);
      }
      .line5_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 44px;
        width: 44px;
        height: 44px;
        font-size: 32px;
        font-weight: bold;
        color: var(--text-color);
        background: rgba(201, 223, 255, 1);
        border-radius: 50%;
      }
    }
    .lon_line6 {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      opacity: 1;
      border-radius: 112px;
      background: rgba(69, 131, 255, 1);
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 18px;
      color: var(--text-color);
    }
    .lon_line7 {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 11px;
      .line7_left {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 18px;
        color: rgba(153, 153, 153, 1);
      }
      .line7_right {
        font-size: 14px;
        font-weight: 900;
        letter-spacing: 0px;
        line-height: 21px;
        color: rgba(34, 34, 34, 1);
      }
    }
    .lon_line8 {
      margin-bottom: 20px;
      width: 100%;
      height: 60px;
      opacity: 1;
      border-radius: 16px;
      background: rgba(240, 245, 254, 1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 11px 0 17px;
      .line8_left {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 27px;
        color: rgba(0, 0, 0, 1);
      }
      .line8_right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 67px;
        height: 41px;
        opacity: 1;
        border-radius: 8px;
        background: rgba(69, 131, 255, 1);
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 24px;
        color: var(--text-color);
      }
    }
    .lon_line9 {
      margin-bottom: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 27px;
      color: rgba(69, 131, 255, 1);
    }
    .lon_line10 {
      height: 0px;
      overflow: hidden;

      transition: 0.5s;
      .line10_text {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 18px;
        color: rgba(153, 153, 153, 1);
        margin-bottom: 21px;
      }
    }
  }

  .meiyou {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    img {
      width: 89px;
      height: 158px;
      margin-bottom: 18px;
    }

    span {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 19.6px;
      color: var(--text-color);
    }
  }
}
</style>