import request from '../utils/request';
export default {
    ipouserchildrenlist(address) {
        return request.request({
            url: '/ipo/user/children-list',
            method: "get",
            headers:{
                Authorization: address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },   
        })
    },

    ipouserentrymark(data,address) {
        return request.request({
            url: '/ipo/user/entry-mark',
            method: "post",
            headers:{
                Authorization: address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },   
            data:data
        })
    },
    ipouserlevelinfo(address) {
        return request.request({
            url: '/ipo/user/level-info',
            method: "get",
            headers:{
                Authorization: address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },   
        })
    },
    ipouserholdinfo(address) {
        return request.request({
            url: '/ipo/user/hold-info',
            method: "get",
            headers:{
                Authorization: address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },   
        })
    },
    ipouserlevellist(address) {
        return request.request({
            url: '/ipo/user/level-list',
            method: "get",
            headers:{
                Authorization: address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },   
        })
    },
    ipouserupgrade(data,address) {
        return request.request({
            url: '/ipo/user/upgrade',
            method: "post",
            headers:{
                Authorization: address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },   
            data:data
        })
    },
    iporaffleconfigs(address) {
        return request.request({
            url: '/ipo/raffle/configs',
            method: "get",
            headers:{
                Authorization: address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },   
        })
    },
    iporaffledraw(data,address) {
        return request.request({
            url: '/ipo/raffle/draw',
            method: "post",
            headers:{
                Authorization: address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },   
            data:data
        })
    },
    iporaffleinfo(address) {
        return request.request({
            url: '/ipo/raffle/info',
            method: "get",
            headers:{
                Authorization: address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },   
        })
    },
    ipoconfig(address) {
        return request.request({
            url: '/ipo/config',
            method: "get",
            headers:{
                Authorization: address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },   
        })
    },
    ipouserwalletinfo(address) {
        return request.request({
            url: '/ipo/user/wallet-info',
            method: "get",
            headers:{
                Authorization: address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },   
        })
    },
    walletinfobykey(data,address) {
        return request.request({
            url: '/wallet/info-by-key',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           params:data
        })
    },
    ipouserjoin(data,address) {
        return request.request({
            url: '/ipo/user/join',
            method: "post",
            headers:{
                Authorization: address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },   
            data:data
        })
    },
    ipouserbind(data,address) {
        return request.request({
            url: '/ipo/user/bind',
            method: "post",
            headers: { 'Content-Type':'application/json',
                Authorization: address,
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            data:data
        })
    },
    ipouserrecommender(address) {
        return request.request({
            url: '/ipo/user/recommender',
            method: "get",
            headers: { 'Content-Type':'application/json',
                Authorization: address,
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
}