<template>
	<div class="interestsCenter">
		<div class="title">
			<div style="transform: rotate(90deg); font-size: 20px;" @click="GoBack">
				<van-icon name="down" />
			</div>

			<div>
				49DAO {{ $t('lang.社区权益中心') }}
			</div>
			<div></div>
		</div>
		<div class="title2" v-if="daoInfo.id > 0&& daoInfo.status == '1'"><img src="@/assets/img/left_line_icon.png" alt=""> <span>{{
			$t('lang.恭喜您成为') }}</span> <img src="@/assets/img/right_line_icon.png" alt=""></div>
		<div class="title3" v-if="daoInfo.id > 0&& daoInfo.status == '1'"> <span>{{ $t(nodelist[daoInfo.node_level].name) }}</span></div>
		<div class="goVote">
			{{ $t('lang.去投票') }}
		</div>
		<van-swipe class="swiper_list" ref="swipeRef" :show-indicators="false" @change="change">
			<van-swipe-item class="item">
				<div class="swiper_list_item">
					<img src="@/assets/img/next_icon.png" class="next_icon" mode="" @click="swiperTo(1)" />
					<div class="swiper_list_item_title">
						{{ $t('lang.董事竞选') }}
					</div>
					<div class="swiper_list_item_text">
						{{ $t('lang.竞选成为管理委员会') }} <br /> {{ $t('lang.董事') }}
					</div>
				</div>
				<div class="swiper_list_item">
					<img src="@/assets/img/next_icon.png" class="next_icon" mode="" @click="swiperTo(1)" />
					<div class="swiper_list_item_title">
						{{ $t('lang.发起提案') }}
					</div>
					<div class="swiper_list_item_text">
						{{ $t('lang.发起社区发展提案') }}
					</div>
				</div>
			</van-swipe-item>
			<van-swipe-item class="item">
				<div class="swiper_list_item">
					<img src="@/assets/img/next_icon.png" class="next_icon" mode="" @click="swiperTo(0)" />
					<div class="swiper_list_item_title">
						{{ $t('lang.投票决议') }}
					</div>
					<div class="swiper_list_item_text">
						{{ $t('lang.参与提案投票表决') }}
					</div>
				</div>
				<div class="swiper_list_item">
					<img src="@/assets/img/next_icon.png" class="next_icon" mode="" @click="swiperTo(0)" />
					<div class="swiper_list_item_title">
						{{ $t('lang.获得激励') }}
					</div>
					<div class="swiper_list_item_text">
						{{ $t('lang.获得税收分红激励') }}
					</div>
				</div>
			</van-swipe-item>
		</van-swipe>
		<div class="interestsCenter_box">
			<div class="box_title">
				{{ $t('lang.ONE手续费激励') }}
			</div>
			<div class="box_line" v-loading="walletLoading"
				style="background: linear-gradient(225deg, rgba(250, 255, 255, 1) 0%, rgba(214, 255, 243, 1) 100%);">
				<img src="@/assets/img/jili1.png" class="image" mode="" />
				<div class="box_line_item">
					<div class="box_line_item_title">
						{{ $t('lang.已领取') }}
					</div>
					<div class="box_line_item_num">
						{{ walletInfo.ONE.claimed }}
					</div>
				</div>
				<div class="box_line_item">
					<div class="box_line_item_title">
						{{ $t('lang.待领取') }}
					</div>
					<div class="box_line_item_num">
						{{ walletInfo.ONE.usable }}
					</div>
				</div>
				<div class="box_line_button"  v-if="walletInfo.ONE.usable > 0" @click="daowalletclaim(0)">
					{{ $t('lang.领取') }}
				</div>
				<div class="box_line_button" style="opacity: 0.6;" v-else>
					{{ $t('lang.领取') }}
				</div>
			</div>
			<div class="box_title">
				{{ $t('lang.星石手续费激励') }}
			</div>
			<div class="box_line" v-loading="walletLoading"
				style="background: linear-gradient(225deg, rgba(250, 255, 255, 1) 0%, rgba(214, 242, 255, 1) 100%);">
				<img src="@/assets/img/jili1.png" class="image" mode="" />
				<div class="box_line_item">
					<div class="box_line_item_title">
						{{ $t('lang.已领取') }}
					</div>
					<div class="box_line_item_num">
						{{ walletInfo.SS.claimed }}
					</div>
				</div>
				<div class="box_line_item">
					<div class="box_line_item_title">
						{{ $t('lang.待领取') }}
					</div>
					<div class="box_line_item_num">
						{{ walletInfo.SS.usable }}
					</div>
				</div>
				<div class="box_line_button" style="background: rgba(53, 168, 222, 1);" v-if="walletInfo.SS.usable > 0"
					@click="daowalletclaim(1)">
					{{ $t('lang.领取') }}
				</div>
				<div class="box_line_button" style="opacity: 0.6; background: rgba(53, 168, 222, 1);" v-else>
					{{ $t('lang.领取') }}
				</div>
			</div>
			<div class="box_title">
				{{ $t('lang.GGS手续费激励') }}
			</div>
			<div class="box_line" v-loading="walletLoading"
				style="background: linear-gradient(225deg, rgba(250, 255, 255, 1) 0%, rgba(255, 235, 214, 1) 100%);">
				<img src="@/assets/img/jili1.png" class="image" mode="" />
				<div class="box_line_item">
					<div class="box_line_item_title">
						{{ $t('lang.已领取') }}
					</div>
					<div class="box_line_item_num">
						0
					</div>
				</div>
				<div class="box_line_item">
					<div class="box_line_item_title">
						{{ $t('lang.待领取') }}
					</div>
					<div class="box_line_item_num">
						0
					</div>
				</div>
				<!-- <div class="box_line_button" style="background: rgba(247, 147, 30, 1);">
					{{ $t('lang.领取') }}
				</div> -->
				<div class="box_line_button" style="opacity: 0.6;background: rgba(247, 147, 30, 1);">
					{{ $t('lang.领取') }}
				</div>
			</div>


			<div style="display: flex;  align-items: center;">
				<div :class="checkListActive == 0 ? 'box_title' : 'box_title box_title_noactive'"
					@click="checkListActiveFunc(0)">
					{{ $t('lang.ONE账单') }}
				</div>
				<div :class="checkListActive == 1 ? 'box_title' : 'box_title box_title_noactive'"
					@click="checkListActiveFunc(1)">
					{{ $t('lang.星石账单') }}
				</div>
				<div :class="checkListActive == 2 ? 'box_title' : 'box_title box_title_noactive'"
					@click="checkListActiveFunc(2)">
					{{ $t('lang.GGS账单') }}
				</div>
			</div>
			<div>
				<div class="box_list">
					<van-list v-if="billlist.list.length > 0" v-model="billlist.loading" :finished="billlist.finished"
						:finished-text="`${$t('lang.d145')}`" @load="onLoadbilllist">
						<div class="box_list_li">
							<div class="box_list_li_item">
								{{ $t('lang.时间') }}
							</div>
							<div class="box_list_li_item">
								{{ $t('lang.数量') }}
							</div>
							<div class="box_list_li_item">
								{{ $t('lang.币种') }}
							</div>
						</div>

						<div class="box_list_li" v-for="(item, index) in billlist.list">
							<div class="box_list_li_item">
								{{ item.created_at }}
							</div>
							<div class="box_list_li_item" style="color: rgba(102, 180, 174, 1);">
								{{ item.usable_change }}
							</div>
							<div class="box_list_li_item" style="color: rgba(34, 34, 34, 1);">
								{{ item.token }}
							</div>
						</div>
					</van-list>

					<div class="nolist" v-else>
						<img src="@/assets/imgsnowball/no_list.png" mode=""></img>
						<div class="">
							{{ $t('lang.暂无数据') }}！
						</div>
					</div>
				</div>
			</div>


		</div>
		<van-popup v-model="popupshow" mode="center" @close="closepopupshow" round>
			<div class="popup">
				<img src="@/assets/img/reg_ture.png" mode="" style="width: 84px; height: 84px"></img>
				<span style="
		        font-size: 16px;
		        color: #000;
		        margin-top: 32px;
		        margin-bottom: 49px;">{{ $t('lang.领取成功') }}</span>
				<div class="popup-button" @click="closepopupshow">{{ $t('lang.确定') }} </div>
			</div>
		</van-popup>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { dao,web3_api } from '@/api/index';
export default {
	data() {
		return {
			walletLoading: false,
			popupshow: false,
			checkListActive: 0,
			billlist: {
				loading: false,
				finished: false,
				list: [],
				info: {
					page: 1,
					page_size: 10,
					type: -1,
					token_id:2,
				}
			},
			daoInfo: {},
			walletInfo: {
				ONE: {},
				SS: {},
			},
			nodelist: [
				{
					name: 'lang.社区节点',
					icon: require('@/assets/img/node_level1.png'),
					bgColor: '#0F5563'
				},
				{
					name: 'lang.服务节点',
					icon: require('@/assets/img/node_level2.png'),
					bgColor: '#0F5563'
				},
				{
					name: 'lang.超级节点',
					icon: require('@/assets/img/node_level3.png'),
					bgColor: 'rgba(0, 0, 0, 0.6)'
				}
			],
		};
	},
	components: {

	},
	watch: {
		account() {
			this.init();
			// this.finished = false;
		},
		lang() {
			this.init();
		},

	},
	computed: {
		...mapState(['account', 'lang']),
	},
	created() {
		this.init();
	},
	methods: {
		change() {

		},
		onLoadbilllist() {
			this.getBillList()
		},
		checkListActiveFunc(index) {
			this.checkListActive = index
			this.initBilllist()
			this.getBillList()
		},
		GoBack() {
			this.$router.go(-1)
		},
		swiperTo(index) {
			this.$refs.swipeRef.swipeTo(index)
		},
		closepopupshow() {
			this.popupshow = false
		},
		init() {
			if (this.account) {
				this.initBilllist()
				Promise.all([
					this.daousernodeinfo(),
					this.getBillList(),
					this.daowalletlist()
				])
			}

		},
		daowalletlist() {
			this.walletLoading = true
			dao.daowalletlist(this.account).then(res => {
				if (res.code == 200) {
					res.data.forEach(item => {
						this.walletInfo[item.token_key] = item
					})
					this.walletLoading = false
				}

			})
		},
		async daowalletclaim(type) {
          
			let sign;
			try {
				sign = await web3_api.signMessage('Dao Reward Claim', this.account)
			} catch (error) {
				this.$toast($t('lang.签名失败'))
				return;
			}
			let data = {
				type,
				signature: sign
			}
			dao.daowalletclaim(data, this.account).then(res => {
				this.$toast(res.msg)
				if (res.code == 200) {
					this.popupshow = true
					this.init()
				}
			})
		},
		initBilllist() {
			this.billlist = {
				loading: false,
				finished: false,
				list: [],
				info: {
					page: 1,
					page_size: 10,
					type: -1,
					token_id: this.checkListActive == 0 ? 2 : this.checkListActive == 1 ? 11 : 0
				}
			}
		},
		getBillList() {
			this.billlist.loading = true
			dao.daowalletrecords(this.billlist.info, this.account).then(res => {
				if (res.data.list != null || res.data.list.length > 0) {
					if (this.billlist.info.page == 1) {
						this.billlist.list = res.data.list
					} else {
						this.billlist.list = this.billlist.list.concat(res.data.list)
					}
					if (res.data.list.length < this.billlist.info.page_size) {

						this.billlist.finished = true
					} else {
						this.billlist.info.page++
					}
                 
				}
				this.billlist.loading = false
			})
		},
		daousernodeinfo() {
			dao.daousernodeinfo(this.account).then(res => {
				if (res.code == 200) {
					this.daoInfo = res.data
				}
			})
		},
	},
};
</script>

<style lang="less" scoped>
.interestsCenter {
	padding-top: 70px;
	width: 100%;
	min-height: 100vh;
	padding-bottom: 76px;
	background: url('~@/assets/img/interestsCenter_bg.png') no-repeat;
	background-size: contain;
	display: flex;
	flex-direction: column;
	align-items: center;

	.title2 {
		margin: 17px 0 12px 0;
		display: flex;
		align-items: center;
		font-size: 12px;
		font-weight: 500;
		color: rgba(0, 0, 0, 1);

		img {
			width: 80.5px;
			height: 7px;
		}

		span {
			margin: 0 7.5px;
		}
	}

	.title3 {
		margin-bottom: 12px;
		font-size: 20px;
		font-weight: 700;
		background: linear-gradient(90deg, rgba(69, 135, 143, 1) 0%, rgba(127, 210, 219, 1) 100%);
		-webkit-background-clip: text;
		color: transparent;
	}

	.title {
		padding: 0 20px;
		width: 100%;
		font-size: 28px;
		font-weight: 700;
		text-align: center;
		color: rgba(34, 34, 34, 1);
		display: flex;
		align-items: center;
		justify-content: space-between;

	}

	.goVote {
		margin-top: 20px;
		width: 191px;
		height: 48px;
		border-radius: 72px;
		background: rgba(102, 180, 174, 1);
		box-shadow: 0px 4px 8px rgba(255, 255, 255, 0.12);
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		font-weight: 700;
		color: rgba(255, 255, 255, 1);
	}
}

.swiper_list {
	margin-bottom: 25px;
	margin-top: 264px;
	width: 100%;
	height: 100px;
	border-bottom: 3px solid rgba(102, 180, 174, 1);

	.item {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: space-around;

		.swiper_list_item:nth-child(2n) {
			background: linear-gradient(0deg, rgba(102, 180, 174, 0.18) 0%, rgba(255, 255, 255, 0) 100%);

			.swiper_list_item_title {
				color: rgba(46, 46, 46, 1);
			}
		}

		.swiper_list_item {
			position: relative;
			padding-top: 15px;
			width: 158px !important;
			height: 100px;
			opacity: 1;
			background: linear-gradient(0deg, rgba(102, 180, 174, 0.71) 0%, rgba(255, 255, 255, 0) 100%);
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			.next_icon {
				position: absolute;
				width: 20px;
				height: 20px;
				right: -10px;
				top: 50%;
				transform: translateY(-50%);
			}

			.swiper_list_item_title {
				margin-bottom: 15px;
				color: rgba(69, 135, 143, 1);
				font-size: 22px;
				font-weight: 700;
			}

			.swiper_list_item_text {
				line-height: 18px;
				text-align: center;
				color: rgba(102, 102, 102, 1);
				font-size: 13px;
				font-weight: 500;
			}
		}

	}

}

.interestsCenter_box {
	border-radius: 30px 30px 0px 0px;
	background: rgba(255, 255, 255, 1);
	width: 100%;
	padding: 20px 18px;
	padding-bottom: 40px;

	.nolist {
		width: 100%;
		height: 300px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-weight: 400;
		color: rgba(153, 153, 153, 1);

		img {
			width: 171.07px;
			height: 121.69px;
			margin-bottom: 19px;
		}
	}

	.box_title {
		margin-right: 10px;
		margin-bottom: 8px;
		font-size: 18px;
		font-weight: 700;
		color: rgba(46, 46, 46, 1);
	}

	.box_title_noactive {
		font-size: 16px;
		font-weight: 500;
		color: rgba(153, 153, 153, 1);
	}

	.box_line {
		margin-bottom: 28px;
		padding: 19px 14px;
		width: 100%;
		height: 182rpx;
		opacity: 1;
		border-radius: 10px;
		background: linear-gradient(225deg, rgba(250, 255, 255, 1) 0%, rgba(214, 242, 255, 1) 100%);
		display: flex;
		align-items: center;
		justify-content: space-between;

		.image {
			width: 54px;
			height: 54px;
		}

		.box_line_item {
			height: inherit;
			display: flex;
			flex-direction: column;

			justify-content: center;

			.box_line_item_title {
				font-size: 12px;
				font-weight: 500;
				opacity: 0.3;
				color: rgba(0, 0, 0, 1);
				margin-bottom: 12px;
			}

			.box_line_item_num {
				font-size: 20px;
				font-weight: 500;
				color: rgba(31, 149, 144, 1);
			}
		}

		.box_line_button {
			align-self: self-end;
			width: 48px;
			height: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 4px;
			background: rgba(102, 180, 174, 1);
			color: rgba(255, 255, 255, 1);
			font-size: 12px;
		}
	}
}

.box_list {
	padding: 16px;
	width: 100%;
	height: 304px;
	opacity: 1;
	border-radius: 14px;
	overflow-y: scroll;
	border: 1px solid rgba(242, 242, 242, 1);

	.box_list_li {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.box_list_li_item:nth-child(1) {
			text-align: left;
		}

		.box_list_li_item:nth-child(3) {
			text-align: right;
		}

		.box_list_li_item {
			flex: 1;
			text-align: center;
			margin-bottom: 20px;
			color: rgba(153, 153, 153, 1);
			font-size: 14px;
			font-weight: 700;
		}
	}
}

.popup {
	width: 304px;
	height: 311px;
	opacity: 1;
	border-radius: 20px;
	background: rgba(255, 255, 255, 1);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.popup-button {
		font-size: 16px;
		font-weight: 700;
		letter-spacing: 0px;
		line-height: 21px;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 246px;
		height: 45px;
		opacity: 1;
		border-radius: 8px;
		background: rgba(102, 180, 174, 1);
	}
}
</style>