<template>
    <div style=" padding-top: 60px;" class="zhitui">
        <div style="display: flex; align-items: center; margin:20px 0;">
            <topbutton router="snowguan" style="color:#000;"></topbutton>
        </div>
        <div class="lc">
            <div class="lc-img">
                <img src="@/assets/img/Hamsterinvite1.png" width="37px" height="auto" style="flex:1;">
                <img src="@/assets/img/Hamsterinvite4.png" width="42px" height="1px" style="flex:1;">
                <img src="@/assets/img/Hamsterinvite2.png" width="41px" height="auto" style="flex:1;">
                <img src="@/assets/img/Hamsterinvite4.png" width="42px" height="1px" style="flex:1;">
                <img src="@/assets/img/Hamsterinvite3.png" width="40px" height="auto" style="flex:1;">
            </div>
            <div class="lc-text">
                <span style="flex:1;">{{ $t('lang.邀请好友') }}</span>
                <span style="flex:1;">{{ $t('lang.好友参与协议') }}</span>
                <span style="flex:1;">{{ $t('lang.获得加速流通') }}</span>
            </div>
        </div>
        <div class="boxs1">
            <div class="box_left">
                <span class="box_num">{{ summaryinfo.max_zone_num }}</span>
                <span class="box_title">{{ $t('lang.最大分享区') }}</span>
            </div>
            <div class="box_right">
                <span class="box_num">{{ summaryinfo.other_zone_num }}</span>
                <span class="box_title">{{ $t('lang.其他分享区') }}</span>
            </div>
        </div>
        <div class="fx">
            <div class="functional_region" id="myElementId">
                <div class="region">
                    <div v-for="(item, index) of functionOption" :key="index" @click="switchovers(index)"
                        :class="index == regionIndex ? 'special' : ''">
                        <span>{{ $t(item.name) }}</span>
                    </div>
                </div>
            </div>
            <div v-if="regionIndex == 0">
                <div class="fx-list" v-if="childrenlist.maxlist.length > 0">
                    <div class="list-title item">
                        <span>{{ $t('lang.地址') }}</span>
                        <span>{{ $t('lang.状态') }}</span>
                        <span>{{ $t('lang.团队有效') }}</span>
                    </div>
                    <div v-for="(item, index) in childrenlist.maxlist" :key="index" class="list-content item">
                        <span>{{ item.address }}</span>
                        <span style="color: #F8931F;" v-if="item.m_is_valid == '1'">{{ $t('lang.成员') }}</span>
                        <span style="color: #999999;" v-else>{{ $t('lang.用户') }}</span>
                        <span>{{ item.team_num }}</span>
                    </div>
                </div>
                <div class="fx-list" v-else style="display: flex;flex-direction: column; align-items: center;">
                    <img src="@/assets/img/no_jiangping.png" width="71px" height="auto">
                    <span style="color: rgba(108, 141, 1, 1); font-size: 12px;">{{ $t('lang.暂无信息') }}~</span>
                </div>
            </div>

            <div v-else>
                <div class="fx-list" v-if="childrenlist.otherlsit.length > 0">
                    <div class="list-title item">
                        <span>{{ $t('lang.地址') }}</span>
                        <span>{{ $t('lang.状态') }}</span>
                        <span>{{ $t('lang.团队有效') }}</span>
                    </div>
                    <div v-for="(item, index) in childrenlist.otherlsit" :key="index" class="list-content item">
                        <span>{{ item.address }}</span>
                        <span style="color: #F8931F;" v-if="item.m_is_valid == '1'">{{ $t('lang.成员') }}</span>
                        <span style="color: #999999;" v-else>{{ $t('lang.用户') }}</span>
                        <span>{{ item.team_num }}</span>
                    </div>
                </div>
                <div class="fx-list" v-else style="display: flex;flex-direction: column; align-items: center;">
                    <img src="@/assets/img/no_jiangping.png" width="71px" height="auto">
                    <span style="color: rgba(108, 141, 1, 1); font-size: 12px;">{{ $t('lang.暂无信息') }}~</span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapState } from 'vuex';
import { nft } from '@/api/index';

import topbutton from '@/components/topbutton.vue';
export default {
    name: '',
    components: {
        topbutton
    },
    data() {
        return {
            childrenlist: {
                maxlist: [],
                otherlsit: [],
            },
            summaryinfo: {},
            regionIndex: 0,
            functionOption: [{ name: 'lang.最大分享区' }, { name: 'lang.其他分享区' }],
        }
    },
    computed: {
        ...mapState(['account', 'lang']),
    },
    created() {
        this.init()
    },
    watch: {
        account() {
            //获取账号
            this.init();
        },
        lang() {
            this.init();
        }
    },
    methods: {
        init() {
            if (this.account) {
                Promise.all([
                    this.nftteaminfo(),
                ])
            }

        },
        switchovers(index) {
            this.regionIndex = index
        },
        nftteaminfo() {
            nft.nftteaminfo(this.account).then(res => {
                if (res.code == 200) {
                    this.summaryinfo = res.data.level_info
                    this.nftchildrenlist();
                }

            })
        },
        nftchildrenlist() {
            nft.nftchildrenlist({}, this.account).then(res => {
                if (res.code == 200) {
                    let array = res.data;
                    if (array != null) {
                        const valueToMatch = this.summaryinfo.max_zone_num;
                        const keyToCheck = 'team_cp';
                        let matchedArray = [];
                        let unmatchedArray = [];

                        let found = false;
                        unmatchedArray = array.filter(item => {
                            if (!found && item[keyToCheck] === valueToMatch) {
                                matchedArray.push(item);
                                found = true;
                                return false; // 不加入unmatchedArray
                            }
                            return true; // 加入unmatchedArray
                        });
                        unmatchedArray.sort((a, b) => {
                            if (a[keyToCheck] < b[keyToCheck]) {
                                return 1;
                            }
                            if (a[keyToCheck] > b[keyToCheck]) {
                                return -1;
                            }
                            return 0;
                        });

                        this.childrenlist.maxlist = matchedArray;
                        this.childrenlist.otherlsit = unmatchedArray;
                    }
                }
            })
        }
    },
}
</script>

<style lang="less" scoped>
.zhitui {
    padding: 20px;
    background: #F5F5F5;
    background-size: 100% auto;

    /* 防止背景图重复 */
    overflow: hidden;
    /* 隐藏多余的部分 */
    min-height: 100vh;
}

.boxs1 {
    margin-top: 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &>div {
        width: 48%;
        height: 88px;
        opacity: 1;
        border-radius: 11px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .box_num {
            font-size: 24px;
            font-weight: 700;
            color: #FFFFFF;
        }

        .box_title {
            font-size: 14px;
            font-weight: 500;
            color: #000000;
        }
    }

    .box_left {
        background: #F8931F;

        border: 1px solid #000000;
    }

    .box_right {
        background: #B88E75;

        border: 1px solid #000000;
    }
}

.s_title {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    font-family: 'PingFang';
}


.lc {
    display: flex;
    flex-direction: column;

    .lc-img {
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: center;
        margin: 0 23px;
        gap: 20px;
    }

    .lc-text {
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: center;
        margin: 10px 20px;
        gap: 20px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 21px;
        color: #000000;
    }
}

.line {
    height: 1px;
    width: 100%;
    background: #fff;
    opacity: 0.2;
}

.fx {
    text-align: center;

    .functional_region {
        margin-top: 12px;
        padding-top: 18px;
        border-top: 1px solid rgba(255, 255, 255, 0.2);

        .region {
            display: flex;

            align-items: center;

            div {
                margin-right: 28px;
                color: rgba(0, 0, 0, 0.6);
            }

            .special {
                font-size: 18px;
                color: rgba(0, 0, 0, 1);
                font-weight: bold;
                position: relative !important;

                &::before {
                    content: '';
                    position: absolute !important;
                    width: 36px;
                    height: 2px;
                    border-radius: 25px;
                    background: #F8931F;
                    bottom: -5px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }

    .fx-list {
        border-radius: 14px;
        background: #fff;
        border: 7px solid #F8931F;
        display: flex;
        flex-direction: column;
        padding: 11px 13px;
        margin-top: 15px;

        .list-title {
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 21px;
            color: rgba(153, 153, 153, 1);
            margin-bottom: 20px;
        }

        .list-content {
            font-size: 14px;
            font-weight: 500;
            color: #000000;
            margin-bottom: 16px;
        }

        .item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &>span {
                flex: 1;
            }

            &>span:first-child {
                text-align: left;
            }

            &>span:last-child {
                text-align: right;
            }
        }
    }
}
</style>