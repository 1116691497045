<template>
  <div class="chobi">
    <div class="chozhi">
      <div class="tonzhen" @click="tonzhenfunis">
        <span>{{ stokenname }}</span>
        <van-icon name="arrow-down" />
      </div>
      <van-popup v-model="tonzhenshow" round position="bottom" class="vantonzhenshow">
        <div class="t_li" v-for="(item, index) in tokens" @click="seclettokenfun(item)" :key="index">
          <img :src="`${imgurl}${item.logo}`" alt="" />
          <span> {{ item.token }}</span>
        </div>

        <div class="quxiao">
          <span @click="tonzhenfunis">{{ $t('lang.swap154') }}</span>
        </div>
      </van-popup>
      <p class="c_shul">{{ $t('lang.d22') }}</p>
      <van-field type="number" v-model="toInput" input-align="left" :placeholder="`${$t('lang.d23')} `" class="van-field" />
      <p class="balance"  v-if="tokenId!=''">{{ $t('lang.d24') }}{{ balance }}</p>
      <p class="c_shul">{{ $t('lang.d25') }}</p>

      <div class="tonzhen ruzhang" v-if="ruzhangaddress != ''">
        <span>{{ addressshow }}</span>
       
      </div>
      <div class="tonzhen ruzhang op" v-else>
        <span> </span>
        <div class="r_line"></div>
        <img src="@/assets/imgsnowball/copy.png" alt="" />
      </div>
      <P style="font-size: 14px">
        <div>{{ $t('lang.d99') }}</div>
        <div>{{ $t('lang.d110') }}</div>
      </P>

      <div class="c_button" v-if="showbutton" @click="sendtranform">
        <van-loading type="spinner" color="#313235" v-if="loading" />
        <span v-else>{{ $t('lang.d26') }}</span>
      </div>
      <div class="c_button" v-else style="opacity: 0.6">{{ $t('lang.d26') }}</div>
    </div>
    <p class="c_jilu">{{ $t('lang.d27') }}</p>
    <div class="c_wlist" v-if="chobilist.length == 0">
      <img src="@/assets/imgsnowball/wujilu.png" alt="" />
      <p>{{ $t('lang.d57') }}</p>
    </div>
    <div class="c_list" v-else>
      <van-list v-model="loading1" :finished="finished1" :finished-text="`${$t('lang.d145')}`" @load="onLoad1" class="list_box">
        <div class="li" v-for="(item, index) in chobilist" :key="index">
          <div>
            <div class="c_head">{{ item.behavior_txt }}{{ item.token_name }}</div>
            <div>{{ item.created_time }}</div>
          </div>
          <span style="color: #39dba3">{{ item.usable_change }}</span>
        </div>
      </van-list>
    </div>
  </div>
</template>
  
<script>
import { mapState } from 'vuex';
import { getErc20Contract } from '@/utils/contractHelp';
import { bizhong, homedata, moneymask,web3_api } from '@/api/index';
import Clipboard from 'clipboard';
import Web3 from 'web3';
import erc20 from '@/config/abi/erc20.json';
export default {
  data() {
    return {
      imgurl: '',
      tonzhenshow: false,
      toInput: '',
      value1: 0,
      tokens: [],
      stokenname: this.$t('lang.d21'),
      stokenaddress: '',
      tokenId: '',
      ruzhangaddress: '',
      addressshow: '',
      balance: 0,
      invite_link: '',
      loading: false,
      chobilist: [],
      chain_listItem: {},
      loading1: false,
      finished1: false,
      page: 1,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapState(['account', 'lang']),
    showbutton() {
      return this.stokenname != this.$t('lang.d21') && !!this.toInput;
    },
  },
  mounted() {
    console.log(this.account);
  },
  watch: {
    account() {
      this.init();
      // this.finished = false;
    },
    lang() {
      this.init();
      console.log(this.stokenname);
      console.log(this.$t('lang.d21'));
      if (this.stokenname != this.$t('lang.d21')) {
        this.stokenname = this.$t('lang.d21');
      }
    },
  },
  methods: {
    init() {
      if(this.account){
        this.page = 1;
      this.chobilist =[];
      this.gettokenconfigs();
      this.getimgurl();
      this.getjiluliebiao();
      }
    
    },
    onLoad1() {
      this.page++;
      this.getjiluliebiao();
    },
    copy() {
      this.invite_link = this.account;
      if (this.invite_link) {
        var clipboard = new Clipboard('.copys');
        clipboard.on('success', e => {
          this.$toast(this.$t('lang.swap300'));
          clipboard.destroy(); // 释放内存
        });
        clipboard.on('error', e => {
          this.$toast(this.$t('lang.swap301'));
          clipboard.destroy(); // 释放内存
        });
      }
    },
    tonzhenfunis() {
      this.tonzhenshow = !this.tonzhenshow;
    },
    gettokenconfigs() {
      bizhong.gettokenrechargeconfigs(this.account).then(res => {
        console.log(res);
        this.tokens = res.data;
      });
    },
    getjiluliebiao() {
      let data = {
        page: this.page,
        page_size: 10,
        type_arr: '[0]',
      };
      moneymask.getjiluliebiao(data, this.account).then(res => {
        if (res.data.list.length != 0) {
          this.chobilist = [...this.chobilist, ...res.data.list];
        }

        if (res.data.list.length < 10) {
          this.finished1 = true;
        }
      });
    },
    //   async getbanceof (){
    //          let web3 = new Web3(window.ethereum);
    //          this.balance =await web3.eth.getBalance(this.account)
    //          this.balance =web3.utils.fromWei(this.balance ,'ether')
    //          this.balance = parseFloat(this.balance).toFixed(3)
    //     },
    getimgurl() {
      homedata.gettokenimgurl().then(res => {
        this.imgurl = res.data.image_domain;
      });
    },
    async getpostaddress() {
      // let web3 = new Web3(window.ethereum);
      //     const sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Post Address'), this.account)
      // console.log("sign",sign);
      let data = {
        chain_parent_id: this.chain_listItem.parent_chain_id,
        token_id: this.tokenId,
      };

      moneymask.postaddress(data, this.account).then(res => {
        console.log(res);
        this.ruzhangaddress = res.data.address;
        const head = this.ruzhangaddress.slice(0, 4);
        const tail = this.ruzhangaddress.slice(this.ruzhangaddress.length - 4);
        this.addressshow = head + '****' + tail;
      });
    },
    seclettokenfun(item) {
      this.tonzhenshow = false;
      this.stokenname = item.token;
      this.chain_listItem = item.chain_list[0];
      this.stokenaddress = this.chain_listItem.contract;
      this.geterc20banceof();
      this.tokenId = item.token_id;
      this.getpostaddress();

      
    },
    async sendtranform() {
      if(this.balance <= 0) return this.$toast(this.$t('lang.d80'));
      if (parseFloat(this.toInput) <= parseFloat(this.balance)) {
        if (parseFloat(this.toInput) <= 0) {
          this.$toast(this.$t('lang.d79'));
        } else {
          this.sendf();
        }
      } else {
        this.$toast(this.$t('lang.d80'));
      }
    },
    async sendf() {
      this.loading = true;
      // const erc20Contract = getErc20Contract(this.stokenaddress);
      let web3 = new Web3(window.ethereum);
      const erc20Contract = new web3.eth.Contract(erc20, this.stokenaddress);
      const amount = web3.utils.toWei(this.toInput, 'ether');
      console.log(amount);
      const gas = await erc20Contract.methods.transfer(this.ruzhangaddress, amount).estimateGas({ from: this.account });
      console.log(gas);
      //  erc20Contract.methods.approve(this.ruzhangaddress ,amount).send({from:this.account})
      erc20Contract.methods
        .transfer(this.ruzhangaddress, amount)
        .send({ from: this.account, gas })
        .once('receipt', res => {
          this.$toast(this.$t('lang.d81'));
          let data = {
            tx_hash: res.transactionHash,
          };
          console.log('232', res.transactionHash);
          moneymask.postruzhuang(data, this.account).then(res => {
          });
          this.init();
          this.geterc20banceof();
          this.loading = false;
        })
        .once('transactionHash', res1 => {
        })
        .once('confirmation', res2 => {
        })
        .once('error', (error, receipt) => {
          this.$toast(this.$t('lang.d82'));
          this.loading = false;
          this.init();
          this.geterc20banceof();
        });
    },
    async geterc20banceof() {
     web3_api.getERC20TokenBalance(this.stokenaddress, this.account).then(res=>{
      this.balance = res;
     })
    
     
    },
  },
};
</script>
  
<style lang="less" scoped>
.chobi {
  padding: 20px;
  width: 100%;
  background: var(--box-bg-color);
  border-radius: 20px;
}

.chozhi {
  width: 100%;
  padding: 15px 20px;

  border-radius: 20px;
  background: ;

  .tonzhen {
    width: 100%;
    height: 52px;
    border-radius: 87px;
    background: var(--line-bg-color);
    padding: 15px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 10px;
      height: 10px;
    }

    span {
      color: var(--text-color) !important;
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
    }
  }

  .vantonzhenshow {
    padding: 20px;
    background: var(--box-bg-color);
    .t_li {
      height: 50px;
      width: 100%;
      display: flex;
      color: var(--text-color);
      align-items: center;
      border-bottom: 1px solid rgba(100, 121, 161, 1);

      img {
        width: 24px;
        height: 24px;
        margin-right: 15px;
        border-radius: 50%;
      }
    }

    .quxiao {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      color: var(--text-color);
      font-size: 16px;
      font-weight: 500;
      font-family: 'PingFang SC';
    }
  }

  .c_shul {
    color: var(--text-color);
    font-size: 16px;
    font-weight: 700;
    font-family: 'PingFang';
    margin-top: 30px;
  }

  .van-field {
    width: 100%;
    border-radius: 87px;
    font-weight: bold;
    background: var(--line-bg-color) !important;
    padding: 16px 20px !important;

    /deep/ .van-field__control {
      font-size: 18px;
      color: var(--text-color);
    }
  }

  .balance {
    color: #9395a4ff;
    font-size: 12px;
    font-weight: 700;
    font-family: 'PingFang';
  }

  .ruzhang {
    position: relative;

    span {
      color: #000;
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
    }

    .r_line {
      height: 15px;
      width: 1px;
      background: #56a0b7;
      position: absolute;
      right: 60px;
      top: 20px;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  .c_button {
    margin-top: 30px;
    width: 100%;
    height: 55px;
    border-radius: 110px;
    background: rgba(102, 180, 174, 1);
    box-shadow: 0 20px 30px 0 #191c321a;
    text-align: center;
    line-height: 55px;
    color: var(--text-color);
    font-size: 16px;
    font-weight: 700;
    font-family: 'PingFang';
  }
}

.c_jilu {
  color: var(--text-color);
  font-size: 18px;
  font-weight: 700;
  font-family: 'PingFang';
  margin-top: 30px;
}

.c_wlist {
  width: 100%;
  height: 219px;
  border-radius: 20px;
  opacity: 1;
  background: var(--line-bg-color);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 122px;
    height: 92px;
  }

  p {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 700;
    font-family: 'PingFang';
  }
}

.c_list {
  width: 100%;
  background: var(--line-bg-color);
  padding: 15px 18px;
  border-radius: 20px;

  .li {
    height: 40px;
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .c_head {
        color: var(--text-color);
        font-size: 14px;
        font-weight: 700;
        font-family: 'PingFang';
      }

      .c_time {
        color: var(--text-color);
        font-size: 12px;
        font-weight: 500;
        font-family: 'PingFang';
      }
    }

    span {
      color: var(--text-color);
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
    }
  }
}

.zhihui {
  margin-top: 30px;
  width: 100%;
  height: 55px;
  border-radius: 65px;
  opacity: 1;
  box-shadow: 0 20px 30px 0 #191c321a;
  text-align: center;
  line-height: 55px;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 700;
  font-family: 'PingFang';
  background: #4583ff;
}

.op {
  opacity: 0.4;
}
</style>