<template>
  <div style=" background:var(--bg-color); padding-top: 20px;">
    <div style="display: flex; align-items: center;margin-left: 20px;">
      <topbutton router="snowguan"
                 style="margin-right: 20px;"></topbutton>
      <p class="s_title">{{ $t('lang.d86') }}</p>
    </div>
    <div class="shoyi">

      <div class="vantList">
        <div class="vantList_item">
          <div>{{ $t('lang.d87') }}</div>
          <div>{{ $t('lang.d88') }}</div>
          <div>{{ $t('lang.d89') }}</div>
          <div>{{ $t('lang.d90') }}</div>
        </div>
        <div class="c_wlist"
             v-if="shoyilist.length == 0">
          <img src="@/assets/imgsnowball/wujilu.png"
               alt="">
          <p>{{ $t('lang.d57') }}</p>
        </div>
        <div v-else>
          <van-list v-model="loading"
                    :finished="finished"
                    :finished-text="`${$t('lang.d145')}`"
                    @load="onLoad">
            <div v-for="(item,index) in shoyilist"
                 class="vantList_item"
                 :key="index">
              <div style="text-align: left;font-size: 12px;">{{ item.created_time }}</div>
              <div style="font-size: 12px;">{{item.type_txt}}</div>
              <div v-if="item.usable_change>=0">+{{ item.usable_change }}</div>
              <div style="color: red;"
                   v-else>{{ item.usable_change }}</div>
              <div>{{ item.token}}</div>

            </div>
          </van-list>
          <!-- <div v-for="item in shoyilist" class="boxs" >
          <div style="width: 30%; flex-shrink: 0;">{{ item.created_time }}</div>
          <div style="color: var( --text-color);">{{item.behavior_txt}}</div>
          <div style="color: #39dba3ff;" v-if="item.usable_change>=0">＋{{ item.usable_change }}</div>
          <div style="color: red;" v-else>{{ item.usable_change }}</div>
          <div style="color: #999999;">{{ item.token_name}}</div>
          
        </div> -->
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import { mapState } from 'vuex';
import { moneymask } from '@/api/index';
import topbutton from '@/components/topbutton.vue';
export default {
  name: '',
  components: {
    topbutton
  },
  data () {
    return {
      shoyilist: [],
      loading: false,
      finished: false,
      page: 1,
      page_size: 10,
      isrun: 0
    }
  },
  computed: {
    ...mapState(['account', 'lang']),
  },
  created () {
    this.getshoyi()
  },
  watch: {
    account () {
      //获取账号
      this.getshoyi();
    },
    lang () {
      this.getshoyi();
    }
  },
  methods: {
    getshoyi () {

      let data = {
        page: 1,
        page_size: this.page_size,
        behavior_arr: '[-1]',
        token_id: 0
      }
      moneymask.getjiluliebiao(data, this.account).then(res => {
        console.log(res);
        this.shoyilist = res.data.list

      })
    },
    onLoad () {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        this.isrun = this.shoyilist.length;
        this.page_size += 10;

        let data = {
          page: 1,
          page_size: this.page_size,
          behavior_arr: '[-1]',
          token_id: 0
        }
        moneymask.getjiluliebiao(data, this.account).then(res => {
          console.log(res);
          this.shoyilist = res.data.list
          if (this.isrun == this.shoyilist.length) {
            this.finished = true
          }
        })
        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        // if (this.shoyilist.length >= 40) {
        //   this.finished = true;
        // }
      }, 1000);

    },
  },
}
</script>

<style lang="less" scoped>
.shoyi {
  width: 100%;
  background: var(--box-bg-color);
  padding: 20px;
  min-height: 100vh;
  border-radius: 20px 20px 0 0;
}

.s_title {
  color: var(--text-color);
  font-size: 18px;
  font-weight: 700;
  font-family: 'PingFang';
}
.vantList {
  width: 100%;
  .vantList_item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(52, 247, 244, 0.49);
    div {
      color: var(--text-color);
      font-size: 16px;
      width: 25%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.c_wlist {
  width: 100%;
  height: 219px;
  border-radius: 20px;
  opacity: 1;

  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 122px;
    height: 92px;
  }

  p {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    font-family: 'PingFang';
  }
}
</style>