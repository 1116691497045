<template>
  <div class="invitation">
    <div class="vip" v-if="!memberHold.is_member">
      <div class="lefttop">{{ $t('lang.d164') }}</div>
      <div class="righttop" @click="gosnowguan">{{ $t('lang.d165') }}</div>
      <img src="@/assets/img/level_logo1.png" class="level_logo" alt="" />
      <div class="viptext">
        <div class="viptext_line1" style="color: rgba(255, 255, 255, 1); font-weight: bold">
          <span>{{ $t('lang.d166') }}</span>
          <span v-show="daoInfo.id == 1"> · DAO</span>
        </div>
        <!-- <span class="viptext_line2">2024.01.23-2024.02.12</span> -->
      </div>
      <div class="vipbutton" v-if="!memberHold.is_renewal" @click="payPopup = true">
        {{ $t('lang.d168') }}
      </div>
      <div class="vipbutton" style="background: #955d18" v-else @click="payPopup = true">
        {{ $t('lang.d188') }}
      </div>
      <!-- <div class="vipbutton" style="opacity: 0.4;" >
        {{$t('lang.d168')}}
      </div>  -->

    </div>
    <div class="vip level2" v-else>
      <div class="lefttop" style="background: rgba(149, 93, 24, 1); color: #fff">{{ $t('lang.d164') }}</div>
      <div class="righttop" @click="gosnowguan" style="background: rgba(247, 204, 151, 1); color: #fff">{{
        $t('lang.d165') }}</div>
      <img src="@/assets/img/level_logo2.png" class="level_logo" style="height: 110px; top: 60px; right: 20px;" alt="">
      <div class="viptext">
        <div class="viptext_line1" style="color: rgba(204, 144, 65, 1);font-weight: bold;">
          {{ $t('lang.d167', { level: level_info.team_level }) }}
          <span v-show="daoInfo.id == 1"> · DAO</span>
        </div>
        <span class="viptext_line2">{{ memberHold.begin_time }}-{{ memberHold.end_time }}</span>
      </div>

    </div>
    <div class="yaoq">
      <p class="y_title">{{ $t('lang.swap80') }}</p>

      <div class="y_line1" v-if="parent_id > 0">{{ linkshow }}</div>
      <div class="y_line1" v-else>{{ $t('lang.swap371') }}</div>

      <div class="y_button copys" @click="copy" :data-clipboard-text="gerenmsg.invite_code" v-if="parent_id > 0">
        {{ $t('lang.d169') }}
      </div>
      <div class="y_button" style="opacity: 0.4" v-else>{{ $t('lang.d169') }}</div>
    </div>
    <div class="jilu">
      <p class="j_title">{{ $t('lang.swap372') }}</p>
      <div class="j_text" @click="popupfunopen" v-if="parent_id == 0">{{ $t('lang.d97') }}</div>
      <div class="j_text" v-else>{{ inviteinfo.recommender }}</div>
      <div class="j_sosuo">
        <span class="s_span">{{ $t('lang.swap63') }}</span>
        <div class="vant">
          <van-icon name="search" class="v_icon" />
          <input type="text" v-model="souoInput" class="van-field2" @input="sosuofun"
            :placeholder="`${$t('lang.swap362')}`" />
          <!-- <van-field type="text"
                           v-model="souoInput"
                           input-align="left"
                           @input="sosuofun"
                           :placeholder="`${$t('lang.swap362')}`"
                           class="van-field2" /> -->
        </div>
      </div>
      <div class="y_cont">
        <div class="c_wlist" v-if="chelist.length == 0">
          <img src="@/assets/imgsnowball/wujilu.png" alt="" />
          <p>{{ $t('lang.d57') }}</p>
        </div>
        <template v-else>
          <div class="list_title">
            <div>{{ $t('lang.d170') }}</div>
            <div>{{ $t('lang.d171') }}</div>
          </div>

          <div class="list_box">
            <div class="c_li" v-for="item in chelist">
              <div>
                {{ item.address }}
              </div>
              <div>
                <span style="color: rgba(204, 144, 65, 1)" v-if="item.is_member == '1'">VIP {{ item.team_level }}</span>
                <span style="color: rgba(204, 144, 65, 1)" v-else-if="item.is_member == '2'">VIP {{ $t('lang.h125')
                  }}</span>
                <span style="color: rgba(168, 168, 168, 1)" v-else-if="item.is_member == '0'">USERS</span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <van-popup v-model="popupshow" round>
      <div class="popup">
        <div class="p_main">
          <p class="m_title">{{ $t('lang.swap372') }}</p>
          <span> {{ $t('lang.d189') }}:</span>
          <van-field type="text" v-model="fromInput" input-align="left" placeholder="" class="van-field" />
          <div class="m_button" @click="postucbin">{{ $t('lang.d78') }}</div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="payPopup" round position="bottom">
      <div class="payPopup">
        <div class="payPopup_title">
          <div>{{ $t('lang.d190') }}</div>
          <div>
            <img src="@/assets/img/close_icon.png" alt="" @click="payPopup = false" />
          </div>
        </div>
        <div class="payPopup_box">
          <div class="payPopup_box_pay_amount">
            <span style="font-size: 48px; margin-right: 3px">{{ payConfig.pay_amount }}</span>
            <span style="font-size: 24px">USDT</span>
          </div>
          <div class="payPopup_box_line" style="margin-top: 45px;opacity: 0.4;" @click="checkIndexFun(0)">
            <img src="@/assets/img/webAccount.png" alt="" class="payPopup_box_line_left" />
            <div class="payPopup_box_line_right">
              <div class="payPopup_box_line_right_left">
                <span style="font-size: 16px; color: #000; margin-bottom: 3px">{{ $t('lang.d191') }}</span>
                <span style="font-size: 12px; color: rgba(153, 153, 153, 1)">{{ $t('lang.d192') }} : {{
                  walletBanlce.webUlsbe }} USDT</span>
              </div>
              <div class="payPopup_box_line_right_check">
                <img src="@/assets/img/check_icon_true.png" alt="" v-if="checkIndex == 0" />
                <img src="@/assets/img/check_icon_false.png" alt="" v-else />
              </div>
            </div>
          </div>
          <div class="payPopup_box_line" style="margin-top: 20px" @click="checkIndexFun(1)">
            <img src="@/assets/img/TerraceAccount.png" alt="" class="payPopup_box_line_left" />
            <div class="payPopup_box_line_right">
              <div class="payPopup_box_line_right_left">
                <span style="font-size: 16px; color: #000; margin-bottom: 3px">{{ $t('lang.d193') }}</span>
                <span style="font-size: 12px; color: rgba(153, 153, 153, 1)">{{ $t('lang.d192') }} : {{
                  walletBanlce.terUlsbe }} USDT</span>
              </div>
              <div class="payPopup_box_line_right_check">
                <img src="@/assets/img/check_icon_true.png" alt="" v-if="checkIndex == 1" />
                <img src="@/assets/img/check_icon_false.png" alt="" v-else />
              </div>
            </div>
          </div>
          <div class="payPopup_box_button" @click="checkBuyFun">
            {{ $t('lang.d196') }}
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="LoadingPopup" round position="bottom">
      <div class="LoadingPopup">
        <div class="status1" v-if="LoadingIndex == 1">
          <van-loading color="#1989fa" size="140" style="margin-bottom: 20px" />
          <div class="LoadingPopup_text" style="margin-bottom: 50px">{{ $t('lang.d194') }}</div>
        </div>
        <div class="status2" v-else-if="LoadingIndex == 2">
          <img src="@/assets/img/success_icon.png" style="margin-top: 22px" alt="" />
          <div class="LoadingPopup_text" style="margin-top: 18px">{{ $t('lang.d195') }}</div>
          <div style="font-weight: 500; color: rgba(34, 34, 34, 1); margin-top: 31px">
            <span style="font-size: 48px; margin-right: 3px">{{ payConfig.pay_amount }}</span>
            <span style="font-size: 24px">USDT</span>
          </div>
          <div class="payPopup_box_button" @click="LoadingPopup = false">
            {{ $t('lang.d197') }}
          </div>
        </div>
        <div class="status2" v-else-if="LoadingIndex == 3">
          <img src="@/assets/img/error_icon.png" style="margin-top: 22px" alt="" />
          <div class="LoadingPopup_text" style="margin-top: 18px">{{ $t('lang.d198') }}</div>
          <div style="font-weight: 500; color: rgba(212, 48, 48, 1); margin-top: 9px; font-size: 12px">
            {{ errorText }}
          </div>
          <div class="payPopup_box_button" @click="LoadingPopup = false">
            {{ $t('lang.d197') }}
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
// import InvitationPopup from '@/views/invitation/InvitationPopup.vue';
import Clipboard from 'clipboard';
import { mapState } from 'vuex';
import { user, homedata, zhubi, vip, dao } from '@/api/index';
import Web3 from 'web3';
import { getErc20Contract } from '@/utils/contractHelp';
import erc20 from '@/config/abi/erc20.json';
export default {
  data() {
    return {
      loading1: false,
      finished1: false,
      page: 1,
      imgurl: '',
      popupshow: false,
      gerenmsg: {
        invite_code: ''
      },
      chelist: [],
      inviteinfo: {},
      invite_linkleft: '',
      fromInput: '',
      souoInput: '',
      tuanduilist: [],
      nexttuandui: {},
      uptuandui: {},
      parent_id: 0,
      payConfig: {},
      memberHold: {},
      payPopup: false,
      checkIndex: 1,
      walletBanlce: {},
      LoadingPopup: false,
      LoadingIndex: 1,
      errorText: 'error',
      level_info: {
        team_level: 0
      },
      daoInfo: {},
    };
  },

  created() {
    this.init();
  },
  watch: {
    account() {
      this.init();
      // this.finished = false;
    },
    lang() {
      this.init();
    },
    updateIndex() {
      this.init();
    }
  },
  computed: {
    ...mapState(['account', 'lang', 'updateIndex']),
    linkshow() {
      var link = `${this.invite_linkleft}/#/?invite_code=${this.gerenmsg.invite_code}`;
      const head = link.slice(0, 22);
      const tail = link.slice(link.length - 5);
      return head + '****' + tail;
    },
  },

  methods: {
    checkIndexFun(index) {
      if (index == 0) return;
      this.checkIndex = index;
    },
    gosnowguan() {
      this.$router.push({ name: 'zhangdan' });
    },
    daouserinfo() {
      dao.daouserinfo(this.account).then(res => {
        if (res.code == 200) {
          this.daoInfo = res.data
        }
      })
    },
    popupfunopen() {
      this.popupshow = true;
    },
    popupfunclose() {
      this.popupshow = false;
    },
    init() {
      if (this.account) {
        this.daouserinfo();
        this.getdata();
        this.getinfo();
        this.ucrecommender();
        this.geturl();
        this.vipmemberlevelinfo()
        this.getimgurl();
        this.vipmemberhold();
        this.vipmemberconfig();
        this.vipwalletinfobykey();
      }


    },
    vipmemberlevelinfo() {
      vip.vipmemberlevelinfo(this.account).then(res => {
        if (res.code == 200) {
          this.level_info = res.data.level_info
        }
      })
    },
    vipmemberhold() {
      vip.vipmemberhold(this.account).then(res => {
        this.memberHold = res.data;
      });
    },
    vipmemberconfig() {
      vip.vipmemberconfig(this.account).then(res => {
        this.payConfig = res.data;
        this.geterc20banceof();
      });
    },
    vipwalletinfobykey() {
      vip.vipwalletinfobykey({ token_key: 'USDT' }, this.account).then(res => {
        if (res.code == 200) {
          this.walletBanlce.terUlsbe = res.data.usable;
        } else {
          this.walletBanlce.terUlsbe = 0;
        }
      });
    },
    async geterc20banceof() {
      let web3 = new Web3(window.ethereum);

      let erc20Contract = null;
      let balance = 0;
      try {
        erc20Contract = getErc20Contract(this.payConfig.contract);
        balance = await erc20Contract.methods.balanceOf(this.account).call();
        balance = web3.utils.fromWei(balance, 'ether');
      } catch (error) {
        balance = 0;
      }

      this.walletBanlce.webUlsbe = parseFloat(balance).toFixed(3);
    },
    checkBuyFun() {
      this.payPopup = false;
      if (!this.memberHold.recommender_is_member) return this.$toast(this.$t('lang.d183'));
      if (!this.memberHold.can_buy) return this.$toast(this.$t('lang.d182'));
      if (this.checkIndex == 0) {
        if (this.payConfig.pay_amount > this.walletBanlce.webUlsbe) return this.$toast(this.$t('lang.d184'));
        this.transferFun();
      } else {
        if (this.payConfig.pay_amount > this.walletBanlce.terUlsbe) return this.$toast(this.$t('lang.d185'));
        this.vipmemberbuy();
      }
    },
    async vipmemberbuy() {
      this.LoadingIndex = 1;
      this.LoadingPopup = true;
      let web3 = new Web3(window.ethereum);
      let sign = '';
      try {
        sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Member Buy'), this.account);
      } catch (error) {
        this.LoadingPopup = false;
        this.LoadingIndex = 3;
        this.errorText = this.$t('lang.d186');
        this.LoadingPopup = true;
        return;
      }
      let data = {
        signature: sign,
      };
      vip.vipmemberbuy(data, this.account).then(res => {
        this.LoadingPopup = false;
        if (res.code == 200) {
          this.LoadingIndex = 2;
          this.init();
        } else {
          this.LoadingIndex = 3;
          this.errorText = res.msg;
        }
        this.LoadingPopup = true;
      });
    },
    async transferFun() {
      let web3 = new Web3(window.ethereum);
      const erc20Contract = new web3.eth.Contract(erc20, this.payConfig.contract);

      const amount = web3.utils.toWei(String(this.payConfig.pay_amount), 'ether');
      console.log(amount);
      const gas = await erc20Contract.methods.transfer(this.payConfig.receiver, amount).estimateGas({ from: this.account });
      console.log(gas);
      let gasPrice = await web3.eth.getGasPrice();
      //  erc20Contract.methods.approve(this.ruzhangaddress ,amount).send({from:this.account})
      erc20Contract.methods
        .transfer(this.payConfig.receiver, amount)
        .send({ from: this.account, gas, gasPrice })
        .on('transactionHash', res => {
          this.LoadingIndex = 1;
          this.LoadingPopup = true;
        })
        .on('receipt', res => {
          this.vipmemberentrymark(res.transactionHash);
        })
        .on('error', err => {
          this.LoadingPopup = false;
          this.LoadingIndex = 3;
          this.errorText = this.$t('lang.d187');
          this.LoadingPopup = true;
        });
    },
    vipmemberentrymark(hash) {
      vip.vipmemberentrymark({ tx_hash: hash }, this.account).then(res => {
        this.LoadingPopup = false;
        if (res.code == 200) {
          this.memberHold.is_member = true;

          let timer = setTimeout(() => {
            this.init();

            clearTimeout(timer);
          }, 120000);

          this.LoadingIndex = 2;
        } else {
          this.LoadingIndex = 3;
          this.errorText = res.msg;
        }
        this.LoadingPopup = true;
      });
    },
    sosuofun() {
      let data = {
        page_size: 10,
        address: this.souoInput,
      };
      user.getucchildrenlist(data, this.account).then(res => {
        console.log(res);
        this.chelist = res.data.list;
      });
    },
    copy() {
      if (this.gerenmsg.invite_code == '') return this.$toast(this.$t('lang.d180'));
      this.invite_link = this.account;
      if (this.invite_link) {
        var clipboard = new Clipboard('.copys');
        clipboard.on('success', e => {
          this.$toast(this.$t('lang.swap300'));
          clipboard.destroy(); // 释放内存
        });
        clipboard.on('error', e => {
          this.$toast(this.$t('lang.swap301'));
          clipboard.destroy(); // 释放内存
        });
      }
    },
    onLoad1() {

    },
    getdata() {

      user.getucchildrenlist({}, this.account).then(res => {
        res.data == null ? this.chelist = [] : this.chelist = res.data;

      });
    },
    async getdengji() {
      await zhubi.gettuandui(this.account).then(res => {
        console.log(res);
        this.nexttuandui = res.data.next_level;

        this.uptuandui = res.data.user_info;
      });
      await zhubi.getproductteamlist(this.account).then(res => {
        console.log(res);
        var a = this.uptuandui.name;
        a = a.substr(-1);
        var b = res.data;

        this.tuanduilist = b.slice(a, b.length);
      });
    },
    getinfo() {
      user.getucinfo(this.account).then(res => {
        console.log(res);
        this.gerenmsg = res.data;
        this.parent_id = this.gerenmsg.parent_id;
        console.log('germsg', this.gerenmsg);
      });
    },
    ucrecommender() {
      user.ucrecommender(this.account).then(res => {
        console.log(res);
        this.inviteinfo.recommender = res.data.recommender;
      });
    },
    getimgurl() {
      homedata.gettokenimgurl().then(res => {
        this.imgurl = res.data.image_domain;
      });
    },
    getinviteinfo() {
      user.getucinviteinfo(this.account).then(res => {
        console.log(res);
        this.inviteinfo = res.data;
      });
    },
    geturl() {
      homedata.gettokenimgurl().then(res => {
        this.invite_linkleft = res.data.domain;
      });
    },
    postucbin() {
      this.popupshow = false;
      let data = {
        invite_code: this.fromInput,
      };
      user.postucbind(data, this.account).then(res => {
        console.log(res);
        this.$toast(res.msg);
        if (res.code == 200) {
          this.$store.commit('SETUPDATEINDEX', this.updateIndex + 1);
          this.init();
        }

      });
    },
  },
  components: {},
};
</script>

<style lang="less" scoped>
.LoadingPopup {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  .LoadingPopup_text {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 18px;
    color: rgba(34, 34, 34, 1);
  }

  .status2 {
    img {
      width: 60px;
      height: 60px;
    }

    .payPopup_box_button {
      margin-top: 120px;
      width: 335px;
      height: 60px;
      opacity: 1;
      border-radius: 110px;
      background: rgba(102, 180, 174, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 18px;
      color: rgba(255, 255, 255, 1);
    }
  }
}

.payPopup {
  width: 100%;

  .payPopup_title {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 21px;
    color: rgba(34, 34, 34, 1);
    border-bottom: 1px solid rgba(242, 242, 242, 1);

    img {
      width: 13.44px;
      height: 13.44px;
    }
  }

  .payPopup_box {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .payPopup_box_button {
      margin-top: 36.4px;
      width: 335px;
      height: 60px;
      opacity: 1;
      border-radius: 110px;
      background: rgba(102, 180, 174, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 18px;
      color: rgba(255, 255, 255, 1);
    }

    .payPopup_box_pay_amount {
      margin-top: 12px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 36px;
      color: rgba(34, 34, 34, 1);
    }

    .payPopup_box_line {
      width: 100%;
      display: flex;

      .payPopup_box_line_left {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }

      .payPopup_box_line_right {
        padding-bottom: 13px;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(242, 242, 242, 1);

        .payPopup_box_line_right_left {
          display: flex;
          text-align: left;
          flex-direction: column;
        }

        .payPopup_box_line_right_check {
          width: 22px;
          height: 22px;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}

.list_box {
  margin-bottom: 5rem;
  overflow-y: scroll;
  height: 300px;
}

.list_title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(242, 242, 242, 1);

  div {
    width: 30%;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 20.27px;
    color: rgba(23, 23, 23, 1);
  }
}

.invitation {
  padding-top: 20px;
  width: 100%;
  text-align: center;
  background: rgba(247, 252, 250, 1);

  .vip {
    margin: 0 auto;
    margin-bottom: 22px;
    position: relative;
    width: 337px;
    height: 186px;
    border-radius: 15px;
    background: linear-gradient(139.24deg, rgba(102, 180, 174, 1) 0%, rgba(60, 122, 134, 1) 100%);
    background-size: contain;

    .level_logo {
      position: absolute;
      width: 147px;
      height: 147px;
      right: 0px;
      top: 30px;
    }

    .vipbutton {
      position: absolute;
      bottom: 17px;
      left: 15px;
      width: 112px;
      height: 39px;
      opacity: 1;
      border-radius: 55px;
      background: rgba(240, 240, 240, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 18px;
      color: #000;
    }

    .viptext {
      position: absolute;
      top: 43px;
      left: 15px;
      display: flex;
      flex-direction: column;

      .viptext_line1 {
        font-size: 28px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 33.6px;
        color: rgba(14, 129, 245, 1);
      }

      .viptext_line2 {
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 18px;
        color: rgba(0, 0, 0, 1);
      }
    }

    .righttop {
      position: absolute;
      top: 14px;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 30px;
      opacity: 1;
      border-radius: 15px 0 0 15px;
      background: rgba(189, 227, 255, 1);
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 18px;
      color: rgba(81, 142, 243, 1);
    }

    .lefttop {
      position: absolute;
      top: 6px;
      left: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 68px;
      min-height: 23px;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 18px;
      color: rgba(0, 0, 0, 1);

      background: rgba(171, 204, 235, 1);
      border-radius: 15px 0 15px 0;
    }
  }

  .level2 {
    background: linear-gradient(119.95deg, rgba(254, 251, 236, 1) 0%, rgba(255, 251, 232, 1) 30.96%, rgba(252, 242, 208, 1) 100%) !important;
    background-size: contain !important;
  }

  .yaoq {
    width: 90%;
    margin: 0 auto;

    padding: 20px;
    border-radius: 20px;
    opacity: 1;
    background: #ffffff;
    box-shadow: 0 0 12px 0 #080a1b1a;
    text-align: center;

    .y_line1 {
      width: 100%;
      height: 52px;
      border-radius: 10px;
      text-align: center;
      line-height: 52px;
      color: #000;
      background: rgba(240, 240, 240, 1);
    }

    .y_title {
      color: #000;
      font-size: 20px;
      font-weight: 700;
      font-family: 'PingFang';
    }

    .y_button {
      margin-top: 30px;
      width: 100%;
      height: 52px;
      border-radius: 10px;
      background: rgba(102, 180, 174, 1);
      box-shadow: 0 0 12px 0 #080a1b1a;
      text-align: center;
      line-height: 52px;
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      font-family: 'PingFang';
    }
  }

  .jilu {
    width: 100%;

    border-radius: 20px;
    margin-top: 20px;
    background: #ffffff;
    padding: 21px;

    .j_title {
      text-align: left;
      color: #000;
      font-size: 18px;
      font-weight: 700;
      font-family: 'PingFang';
    }

    .j_text {
      width: 100%;
      height: 59px;
      border-radius: 20px;
      background: rgba(240, 240, 240, 1);
      box-shadow: 0 0 12px 0 #080a1b1a;
      color: #000;
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
      text-align: center;
      line-height: 59px;
    }

    .j_sosuo {
      width: 100%;

      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;

      .s_span {
        color: #000;
        font-size: 18px;
        font-weight: 700;
        font-family: 'PingFang';
      }
    }
  }
}

.van-field {
  width: 228px;
  margin: 0 auto;

  height: 48px;
  border-radius: 4px;
  font-weight: bold;
  background: rgba(240, 245, 254, 1) !important;
  padding: 14px !important;

  /deep/ .van-field__control {
    font-size: 14px;
    color: #000;
  }
}

.vant {
  width: 206px;
  position: relative;
  background: rgba(240, 240, 240, 1);
  border-radius: 20px;
  height: 34px;

  .v_icon {
    position: absolute;
    left: 15px;
    top: 11px;
  }

  .van_sosuo {
    float: right;
    padding: 0;
    color: #fff;
    width: 206px;
    height: 38px;

    border: none;
    background: none;
    box-shadow: 0 0 12px 0 #080a1b1a;
  }
}

.y_cont {
  width: 100%;
  margin-top: 30px;

  .c_li {
    border-bottom: 1px solid rgba(242, 242, 242, 1);
    height: 43px;
    color: #999999ff;
    font-size: 14px;
    font-weight: 700;
    font-family: 'PingFang';
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      width: 30%;
      text-align: center;
    }
  }
}

.popup {
  .p_main {
    width: 288px;

    border-radius: 10px;
    padding: 10px;
    background: #ffffffff;

    .m_button {
      width: 228px;
      height: 48px;
      border-radius: 4px;
      margin: 0 auto;
      margin-top: 20px;
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
      text-align: center;
      line-height: 48px;
      background: rgba(102, 180, 174, 1);
    }

    .m_title {
      color: #2f272aff;
      font-size: 20px;
      font-weight: 700;
      font-family: 'PingFang';
      text-align: center;
    }

    span {
      color: #000000ff;
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
      float: left;
      margin-bottom: 20px;
    }
  }
}

.popupx {
  width: 36px;
  height: 36px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -100px;
}

.van-field2 {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 !important;
  padding-top: 5px !important;
  color: #000;
  width: 170px;
  height: 34px;

  border: none;
  background: none !important;

  /deep/ .van-field__control {
    font-size: 14px;
    color: #fff;
  }
}

.c_wlist {
  width: 100%;
  height: 219px;
  border-radius: 20px;
  opacity: 1;

  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 100px;
  }

  p {
    color: #ffffffff;
    font-size: 14px;
    font-weight: 700;
    font-family: 'PingFang';
  }
}
</style>