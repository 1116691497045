import Web3 from "web3";
import pairABI from '@/config/abi/jackPair.json'
import routerABI from '@/config/abi/jackRouter.json'
import factoryABI from '@/config/abi/jackFactory.json'
import erc20ABI from '@/config/abi/erc20.json'
import { significantDigits,accDiv,accMul,formatAmount } from '@/utils/format'
// 初始化 Web3 实例，这里假设你已经有一个节点或提供商的 URL
const web3 = new Web3(window.ethereum || new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/'));

const USDTADDR = '0x55d398326f99059fF775485246999027B3197955'
const contractABIs = {
    pair: pairABI,
    router: routerABI,
    factory: factoryABI,
    erc20: erc20ABI,
};

async function getContractInstance(contractType, contractAddress) {
    try {
        // 从已加载的 ABI 中获取合约的 ABI
        const contractABI = contractABIs[contractType];

        if (!contractABI) {
            throw new Error(`未找到合约类型 ${contractType} 的 ABI`);
        }

        // 创建并返回合约实例
        const contractInstance = new web3.eth.Contract(contractABI, contractAddress);
        return contractInstance;
    } catch (error) {
        console.error(`获取合约实例失败: ${error.message}`);
        return null;
    }
}

async function getIstokenOne(abiPairContractAddress, tokenAddress) {
    const pairContract = await getContractInstance('pair', abiPairContractAddress);
    const token0Fun = pairContract.methods.token0;
    const res = await token0Fun().call();
    const token0 = res.toString();
    return token0.toLowerCase() === tokenAddress;
}
async function getOtherToken(abiPairContractAddress, tokenAddress) {
    const pairContract = await getContractInstance('pair', abiPairContractAddress);

    // 获取 token0 和 token1
    const token0Fun = pairContract.methods.token0;
    const token1Fun = pairContract.methods.token1;

    const res1 = await token0Fun().call();
    const res2 = await token1Fun().call();

    const token0 = res1.toString();
    const token1 = res2.toString();
    // 根据 tokenAddress 返回另一个 token
    if (token0 === tokenAddress) {
        return token1;
    } else {
        return token0;
    }
}

async function getCurrentPairPrice(
    abiContractAddressList, // Array of contract addresses
    tokenAddress, // Contract address of the first token
    decimalsToken0, // Decimals of the first token
    decimalsToken1 // Decimals of the second token
) {
    try {
        if (abiContractAddressList[0].toLowerCase() === tokenAddress.toLowerCase()) {
            return 1;
        }

        // If the array length is 1, get the price directly from the pool
        if (abiContractAddressList.length === 1) {
            const abiContractAddress = abiContractAddressList[0];
            const pairContract = await getContractInstance('pair', abiContractAddress);

            const reservesFunction = pairContract.methods.getReserves;
            const reserves = await reservesFunction().call();

            const reserve0 = BigInt(reserves[0]);
            const reserve1 = BigInt(reserves[1]);

            // Adjust the reserve amounts to make the two tokens have the same unit
            const adjustedReserve0 = Number(reserve0) / (10 ** decimalsToken0);
            const adjustedReserve1 = Number(reserve1) / (10 ** decimalsToken1);
            const istoken0 = await getIstokenOne(abiContractAddress, tokenAddress);
            const otherToken = await getOtherToken(abiContractAddress, tokenAddress);
            // Calculate the price, token1/token0
            if (istoken0) {
                return significantDigits(adjustedReserve1 / adjustedReserve0);
            } else {
                return significantDigits(adjustedReserve0 / adjustedReserve1);
            }

            // If the array length is 2, get the price across the transaction
        } else if (abiContractAddressList.length === 2) {
            // First contract address (e.g., USDT and some token pool)
            const abiContractAddress1 = abiContractAddressList[0];
            const pairContract1 = await getContractInstance('pair', abiContractAddress1);

            const reservesFunction1 = pairContract1.methods.getReserves;
            const reserves1 = await reservesFunction1().call();

            const reserve0_1 = BigInt(reserves1[0]);
            const reserve1_1 = BigInt(reserves1[1]);

            // Adjust the reserve amounts to make the two tokens have the same unit
            const adjustedReserve0_1 = Number(reserve0_1) / (10 ** decimalsToken0);
            const adjustedReserve1_1 = Number(reserve1_1) / (10 ** decimalsToken1);

            let pricePool1;
            const istoken0 = await getIstokenOne(abiContractAddress1, tokenAddress);
            // Middle pool token address (Token/USDT)
            const otherToken = await getOtherToken(abiContractAddress1, tokenAddress);

            // Calculate the price, token1/token0
            if (istoken0) {
                pricePool1 = adjustedReserve1_1 / adjustedReserve0_1;
            } else {
                pricePool1 = adjustedReserve0_1 / adjustedReserve1_1;
            }

            // Second contract address (e.g., cross transaction pool address)
            const abiContractAddress2 = abiContractAddressList[1];
            const pairContract2 = await getContractInstance('pair', abiContractAddress2);

            const reservesFunction2 = pairContract2.methods.getReserves;
            const reserves2 = await reservesFunction2().call();

            const reserve0_2 = BigInt(reserves2[0]);
            const reserve1_2 = BigInt(reserves2[1]);

            // Adjust the reserve amounts to make the two tokens have the same unit
            const adjustedReserve0_2 = Number(reserve0_2) / (10 ** decimalsToken0);
            const adjustedReserve1_2 = Number(reserve1_2) / (10 ** decimalsToken1);

            const istoken0_1 = await getIstokenOne(abiContractAddress2, otherToken);
            let pricePool2;

            if (istoken0_1) {
                pricePool2 = adjustedReserve1_2 / adjustedReserve0_2;
            } else {
                pricePool2 = adjustedReserve0_2 / adjustedReserve1_2;
            }

            // The final price across pools is the product of the two pool prices
            return significantDigits(pricePool1 * pricePool2);
        } else {
            throw new Error('Incorrect length of contract address array');
        }
    } catch (e) {
        console.error(`Failed to get price: ${e}`);
        return 0.0;
    }
}


async function getLP_TVL_InUSDT(lpAddress) {
    try {
        // 获取合约实例
        const pairContract = await getContractInstance('pair', lpAddress);

        // 获取 token0 和 token1 地址
        const [token0, token1] = await Promise.all([
            pairContract.methods.token0().call(),
            pairContract.methods.token1().call(),
        ]);

        // 获取 token0 和 token1 的价格（以 USDT 计价）
        const [priceToken0Price, priceToken1Price] = await Promise.all([
            getCurrentPairPrice([lpAddress], token0, 18, 18),
            getCurrentPairPrice([lpAddress], token1, 18, 18),
        ]);

        // 获取储备量和总供应量
        const [reserves, totalSupply] = await Promise.all([
            pairContract.methods.getReserves().call(),
            pairContract.methods.totalSupply().call(),
        ]);

        // 解析储备金
        let reserveNum;
        if(token0.toLowerCase() === USDTADDR.toLowerCase()){
            reserveNum =  reserves._reserve1 / Math.pow(10, 18);
        }else{
            reserveNum = reserves._reserve0 / Math.pow(10, 18);
        }

        const totalValueInUSDT = (reserveNum * priceToken0Price)*2;

        // 计算 1 个 LP 的价值
        const lpValue = totalValueInUSDT / (totalSupply / Math.pow(10, 18));

        return significantDigits(lpValue); // 返回格式化的结果
    } catch (error) {
        console.error(`获取 1 个 LP 的价值失败: ${error.message}`);
        return null;
    }
}






function requestWeb3ApplianceAccount() {
    return new Promise((resolve, reject) => {
        // 获取当前账户
        if (window.ethereum) {
            window.ethereum.request({ method: 'eth_requestAccounts' }).then(accounts => {
                resolve(accounts[0]);
            }).catch(error => {
                reject(error);
            });
        } else {
            reject('Please install MetaMask');
            this.$toast('Please use Dapp related applications to open it');
        }

    });
}

function accountsChangedWeb3Appliance() {
    return new Promise((resolve, reject) => {
        // 监听账户变化
        window.ethereum.on('accountsChanged', function (accounts) {
            resolve(accounts[0]);
        });
    });
}

async function getERC20TokenInfo(tokenAddress) {
    try {
        // 获取 ERC20 合约实例
        const erc20Contract = await getContractInstance('erc20', tokenAddress);

        if (!erc20Contract) {
            throw new Error('无法获取 ERC20 合约实例');
        }

        // 获取代币名称、符号、总供应量和精度
        const [name, symbol, totalSupply, decimals] = await Promise.all([
            erc20Contract.methods.name().call(),
            erc20Contract.methods.symbol().call(),
            erc20Contract.methods.totalSupply().call(),
            erc20Contract.methods.decimals().call(),
        ]);

        return {
            name,
            symbol,
            totalSupply: totalSupply.toString(), // 转换为字符串
            decimals: parseInt(decimals, 10),   // 转换为整数
        };
    } catch (error) {
        console.error('获取 ERC20 代币信息失败:', error.message);
        return null;
    }
}

async function getERC20TokenBalance(tokenAddress, userAddress) {

    try {
        // 获取代币精度
        const { decimals } = await getERC20TokenInfo(tokenAddress);

        if (decimals === null) {
            throw new Error('无法获取代币精度');
        }

        // 获取 ERC20 合约实例
        const erc20Contract = await getContractInstance('erc20', tokenAddress);

        if (!erc20Contract) {
            this.$toast('无法获取代币余额')
            throw new Error('无法获取 ERC20 合约实例');

        }

        // 获取代币余额
        const balance = await erc20Contract.methods.balanceOf(userAddress).call();
        // 将 balance 转换为 BigInt 类型
        const bigIntBalance = BigInt(balance);

        // 将 BigInt 转换为字符串并插入小数点
        const balanceString = bigIntBalance.toString();

        // 如果 balance 的位数比 decimals 小，补齐前面的 0
        let readableBalance = '';
        if (balanceString.length <= decimals) {
            readableBalance = '0.' + balanceString.padStart(decimals, '0');
        } else {
            // 在正确的位置插入小数点
            readableBalance = balanceString.slice(0, balanceString.length - decimals) + '.' + balanceString.slice(balanceString.length - decimals);
        }
        // 返回最终的可读余额
        return significantDigits(readableBalance);
    } catch (error) {
        console.error(`获取代币余额失败: ${error.message}`);
        return null;
    }
}


async function transferERC20Token(tokenAddress, senderAddress, recipientAddress, amount) {
    return new Promise(async (resolve, reject) => {
        try {
            // 获取 ERC20 合约实例
            const erc20Contract = await getContractInstance('erc20', tokenAddress);

            if (!erc20Contract) {
                this.$toast('无法获取 ERC20 合约实例');
                return reject(new Error('无法获取 ERC20 合约实例'));
            }
            // 获取代币的精度
            const { decimals } = await getERC20TokenInfo(tokenAddress);

            if (decimals === null) {
                return reject(new Error('无法获取代币精度'));
            }
            // 将 amount 转换为最小单位（根据精度）
            const bigIntAmount = BigInt(amount * (10 ** decimals));

            const gas = await erc20Contract.methods
                .transfer(recipientAddress, bigIntAmount) // 使用最小单位
                .estimateGas({ from: senderAddress });
            // 使用 Metamask 进行代币转账
            const transactionHash = await erc20Contract.methods
                .transfer(recipientAddress, bigIntAmount.toString()) // 使用最小单位
                .send({ from: senderAddress, gas })
            return resolve(transactionHash);
        } catch (error) {
            reject(error);  // 失败时返回错误
        }
    });
}

async function signMessage(message, fromAddress) {
    return new Promise(async (resolve, reject) => {
        try {
            const sign = await web3.eth.personal.sign(message, fromAddress,'');
            resolve(sign); // 返回签名
        } catch (error) {
            reject(error); // 失败时返回错误
        }
    });
}


async function isETHAddress (address) {
    return new Promise(async (resolve, reject) => {
        try {
            const isValid = await web3.utils.isAddress(address);
            console.log(isValid);
            
            resolve(isValid);
        } catch (error) {
             
            resolve(/^0x[a-fA-F0-9]{40}$/.test(address));
        }
    });
}








export default {
    getCurrentPairPrice,
    requestWeb3ApplianceAccount,
    accountsChangedWeb3Appliance,
    getERC20TokenInfo,
    getERC20TokenBalance,
    transferERC20Token,
    signMessage,
    isETHAddress,
    getLP_TVL_InUSDT

}