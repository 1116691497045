import request from '../utils/request';

export default {
    daouserinfo(address) {
        return request.request({
            url: '/dao/user/info',
            method: "get",
            headers: { 'Content-Type':'application/json',
                Authorization: address,
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    daousernodeinfo(address) {
        return request.request({
            url: '/dao/user/node-info',
            method: "get",
            headers: { 'Content-Type':'application/json',
                Authorization: address,
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    
    daowalletrecords(data,address) {
        return request.request({
            url: '/dao/wallet/records',
            method: "get",
            headers: { 'Content-Type':'application/json',
                Authorization: address,
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            params:data
        })
    },
    daowalletlist(address) {
        return request.request({
            url: '/dao/wallet/list',
            method: "get",
            headers: { 'Content-Type':'application/json',
                Authorization: address,
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    daowalletclaim(data,address) {
        return request.request({
            url: '/dao/wallet/claim',
            method: "post",
            headers: { 'Content-Type':'application/json',
                Authorization: address,
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            data:data
        })
    },

    
}