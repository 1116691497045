<template>
    <div class="container">
        <div style="display: flex; align-items: center; margin-bottom: 20px;">
            <topbutton router="snowguan" style="color:#000;"></topbutton>
        </div>
        <div class="box1" v-loading="walletinfoLoading">
            <img class="box1_house_img1" src="@/assets/img/house_img1.png" alt="">
            <div class="box1_line">
                <div class="box1_line_left">
                    <div class="box1_line_left_title">{{ $t('lang.矿池余额') }}</div>
                    <div class="box1_line_left_num" style="font-size: 20px;font-weight: 700;">{{ walletinfo.freeze || 0 }}
                    </div>
                </div>
                <div></div>
            </div>
            <div class="box1_line">
                <div class="box1_line_left" style="flex-direction: row;">
                    <div style="margin-right: 44px;">
                        <div class="box1_line_left_title">{{ $t('lang.今日流通') }}</div>
                        <div class="box1_line_left_num">{{ todayreward || 0 }}</div>
                    </div>
                    <div>
                        <div class="box1_line_left_title">{{ $t('lang.累计流通') }}</div>
                        <div class="box1_line_left_num">{{ walletinfo.total_reward || 0 }}</div>
                    </div>
                </div>
                <div class="box1_line_button" @click="GoBill">{{ $t('lang.账单') }}</div>
            </div>
        </div>
        <div class="box2">
            <div class="box">
                <span>{{ $t('lang.我的邀请码') }}</span>
                <div class="inputBox" v-if="UserIniviteInfo.recommender == ''">
                    <input type="text" v-model="invite_code" :placeholder="$t('lang.请填写推荐码')">
                    <div class="inputBox_button" @click="nftuserbind">{{ $t('lang.绑定') }}</div>
                </div>
                <div class="inputBox" v-else>
                    <div class="inputBox_text" style="text-align: left;">
                        {{ UserIniviteInfo.invite_code }}
                    </div>
                    <div class="CopyText" style="width: 24px; height: 24px; margin-left: 21px;"
                        :data-clipboard-text="UserIniviteInfo.invite_code" @click="CopyText">
                        <img style="width: 100%; height: 100%;" src="@/assets/img/copy_white.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="box3">
            <div class="box3_box">
                <img class="hamster_img" src="@/assets/img/house_img2.png" alt="">
                <div class="box3_box_button" @click="GoInvite">{{ $t('lang.查看') }}</div>
                <div class="box3_box_box">
                    <span class="box3_box_box_title">{{ $t('lang.直接分享') }}</span>
                    <span class="box3_box_box_num">{{ teaminfo.m_dr_num  || 0}}</span>
                </div>
            </div>
            <div class="box3_box">
                <img class="hamster_img" src="@/assets/img/house_img3.png" alt="">
                <div class="box3_box_box">
                    <span class="box3_box_box_title">{{ $t('lang.团队分享') }}</span>
                    <span class="box3_box_box_num">{{ teaminfo.m_children_num || 0 }}</span>
                </div>
            </div>
        </div>
        <div class="title_box4">
            {{ $t('lang.销毁挖矿') }}
        </div>
        <div class="box4">
            <div class="box4_title">{{ $t('lang.请输入您的交易哈希') }}</div>
            <div class="box4_input">
                <input type="text" @input="inputTx_hash" v-model="tx_hash" :placeholder="$t('lang.请校验哈希')">
                <div @click="mininguservalidhash"
                    style="border-left: 1px solid #C4BCA9; padding-left: 18px; color: #F8931F;"><van-loading
                        color="#F8931F" v-if="tx_hashLoading" /> <span v-else>{{ $t('lang.校验') }}</span></div>
            </div>
            <div class="box4_title">{{ $t('lang.交易地址') }}</div>
            <div class="box4_input">
                <div>{{ tx_hash_info.address }}</div>
            </div>
            <div class="box4_title">{{ $t('lang.您本次销毁') }}</div>
            <div class="box4_input">
                <div>{{ tx_hash_info.burn_amount }}</div>
                <div style="color: #C4BCA9;">Hamster</div>
            </div>
            <div class="box4_title">{{ $t('lang.您将获得') }}</div>
            <div class="box4_input">
                <div>{{ tx_hash_info.reward_amount }}</div>
                <div style="color: #C4BCA9;">Hamster</div>
            </div>
            <!-- <button @click="cleartx_hashInfo" style="opacity: 0.6;">

                <span class="shadow"></span>
                <span class="edge"></span>
                <span class="front text" style="font-weight: bold;"> {{ $t('lang.确定') }}
                </span>
            </button> -->
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { nft, mining_api, web3_api } from '@/api/index';
import Clipboard from 'clipboard';
import topbutton from '@/components/topbutton.vue';
export default {
    data() {
        return {
            UserIniviteInfo: {
                recommender: '',
                invite_code: ''
            },
            invite_code: '',
            teaminfo: {},
            todayreward: 0,
            walletinfo: {},
            walletinfoLoading: false,
            tx_hash: '',
            tx_hashLoading: false,
            tx_hash_info: {
                address:'0x...........',
                burn_amount:0,
                reward_amount:0
            }
        }
    },
    components: {
        topbutton
    },
    computed: {
        ...mapState(['account', 'lang'])
    },
    watch: {
        account() {
            this.init();
        },
        lang() {
            this.init();
        },

    },
    created() {
        this.init();
    },
    methods: {
        init() {
            if (this.account) {
                Promise.all([
                    this.nftuserrecommender(),
                    this.nftteaminfo(),
                    this.miningusertodayreward(),
                    this.miningwalletinfo()
                ])
            }
        },
        CopyText() {
            var clipboard = new Clipboard('.CopyText');
            clipboard.on('success', e => {
                this.$toast(this.$t('lang.swap300'));
                clipboard.destroy(); // 释放内存
            });
            clipboard.on('error', e => {
                this.$toast(this.$t('lang.swap301'));
                clipboard.destroy(); // 释放内存
            });
        },
        cleartx_hashInfo(){
            this.tx_hash_info = {
                address:'0x...........',
                burn_amount:0,
                reward_amount:0
            }
        },
        inputTx_hash(){
            if(this.tx_hash == '') return this.cleartx_hashInfo();
        },
        async mininguservalidhash() {
            if(this.tx_hash == '') return this.$toast(this.$t('lang.请输入交易哈希'));
            if(this.tx_hashLoading) return ;
            let sign;
            this.tx_hashLoading = true
            try {
                sign = await web3_api.signMessage('Mining Valid Hash', this.account)
            } catch (error) {
                this.$toast(this.$t('lang.签名失败'))
                this.tx_hashLoading = false
                return;
            }
            let data = {
                tx_hash: this.tx_hash,
                signature: sign
            }
            mining_api.mininguservalidhash(data, this.account).then(res => {
                this.tx_hashLoading = false
                if(res.code == 200){
                    this.tx_hash_info = res.data
                    this.init()
                }else{
                    this.tx_hash = ''
                }
                this.$toast(res.msg)
            }).catch(err=>{
                this.tx_hashLoading = false 
            })
        },
        miningusertodayreward() {
            mining_api.miningusertodayreward(this.account).then(res => {
                if (res.code == 200) {
                    this.todayreward = res.data.today_reward

                }
            })
        },
        miningwalletinfo() {
            this.walletinfoLoading = true
            mining_api.miningwalletinfo(this.account).then(res => {
                if (res.code == 200) {
                    this.walletinfo = res.data
                }
                this.walletinfoLoading = false
            }).catch(err => {
                this.walletinfoLoading = false
            })
        },
        nftteaminfo() {
            nft.nftteaminfo(this.account).then(res => {
                if (res.code == 200) {
                    this.teaminfo = res.data.level_info
                }
            })
        },
        nftuserrecommender() {
            nft.nftuserrecommender(this.account).then(res => {
                if (res.code == 200) {
                    this.UserIniviteInfo = res.data
                }
            })
        },
        nftuserbind() {

            if (this.invite_code == '') return this.$toast(this.$t('lang.请填写推荐码'));

            let data = {
                invite_code: this.invite_code,
            }
            nft.nftuserbind(data, this.account).then(res => {
                if (res.code == 200) {
                    this.init()
                }
                this.$toast(res.msg)
            }).catch(err => {
            })
        },
        GoInvite() {
            this.$router.push({ name: 'HamsterInvite' })
        },
        GoBill() {
            this.$router.push({ name: 'HamsterBill' })
        }
    },
}

</script>
<style scoped lang='less'>
.container {
    background: #FFF2D5;
    padding: 18px 20px;
    padding-top: 70px;
    padding-bottom: 70px;
    position: relative;



}

.box1 {
    width: 100%;
    height: 147px;
    opacity: 1;
    border-radius: 8px;
    background: #F8931F;
    padding: 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .box1_line {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .box1_line_left {
            display: flex;
            flex-direction: column;

            .box1_line_left_title {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.6);
            }

            .box1_line_left_num {
                font-size: 12px;
                color: #FFFFFF;
            }
        }

        .box1_line_button {
            z-index: 9;
            color: #F8931F;
            font-size: 14px;
            font-weight: 700;
            padding: 5px 13px;
            border-radius: 4px;
            background: #FFFFFF;
        }
    }

    .box1_house_img1 {
        width: 116px;
        height: 116px;
        position: absolute;

        right: 40px;
        top: 105px;
    }
}

.box2 {
    margin-top: 19px;
    padding: 9px 7px;
    border-radius: 16px;
    background: #FFFFFF;

    .box {
        background-image: url('~@/assets/img/hasmter_box2_bg.png');
        /* 不规则背景图片 */
        background-size: 100% 100%;
        background-position: top;
        background-repeat: no-repeat;
        text-align: center;
        padding: 12px;
        font-size: 14px;
        font-weight: 900;
        letter-spacing: 0px;
        line-height: 19.6px;
        color: rgba(255, 255, 255, 1);

        .inputBox {
            margin-top: 17px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .inputBox_button {
                margin-left: 21px;
                background: rgba(181, 233, 0, 1);
                color: #FFFFFF;
                padding: 9px 10px;
                border-radius: 10px;
            }

            input,
            .inputBox_text {
                background: none;
                outline: none;
                border: none;
                color: #FFFFFF;
                border-radius: 6px;
                padding: 9px 10px;
                flex: 1;
                border: 1px solid #BF6B0B;
            }
        }

        .no_jp {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            font-size: 14px;
            font-weight: 900;
            letter-spacing: 0px;
            line-height: 19.6px;
            color: rgba(108, 141, 1, 1);
        }

        .jp_list {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-size: 12px;
                font-weight: 900;
                letter-spacing: 0px;
                line-height: 16.8px;
                color: rgba(255, 255, 255, 1);
            }
        }
    }

    .line {
        width: 100%;
        /* 虚线宽度 */
        border-top: 1px dashed #b7e9007d;
        /* 虚线颜色和粗细 */
        margin: 11px 0;
        /* 上下间距 */
    }
}

.box3 {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .box3_box {
        position: relative;
        width: 48%;
        height: 123.5px;
        padding: 16px;
        padding-top: 41px;
        border-radius: 10px;
        background: url('~@/assets/img/box3_img_bg.png') no-repeat;
        background-size: contain;

        .box3_box_button {
            position: absolute;
            right: 8px;
            top: 11px;
            padding: 5px 12px;
            border-radius: 73px;
            background: #1D2700;
            font-size: 14px;
            font-weight: 700;
            color: #FFFFFF;
        }

        .hamster_img {
            position: absolute;
            width: 103px;
            height: 104px;
            top: -30px;
            left: 0;
        }

        .box3_box_box {
            margin-top: 20px;
            display: flex;
            flex-direction: column;

            .box3_box_box_title {
                font-size: 24px;
                font-weight: bold;
                color: #1D2700;
            }

            .box3_box_box_num {
                font-size: 16px;
                font-weight: 500;
                color: #1D2700;
            }
        }
    }
}

.title_box4 {
    font-size: 15px;
    font-weight: bold;
    color: #A68D56;
    margin-top: 30px;
    margin-bottom: 10px;
}

.box4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px 12px;
    border: 7px solid #F8931F;
    border-radius: 14px;
    background: #FFFFFF;

    .box4_title {
        font-weight: 700;
        font-size: 14px;
        color: #999999;
        margin-bottom: 10px;
        width: 100%;
    }

    .box4_input {
        flex-shrink: 1;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #C4BCA9;
        padding: 11px 12px;
        border-radius: 9px;
        margin-bottom: 26px;
        color: #000000;
        font-size: 14px;

        input {
            background: none;
            border: none;
        }
    }

    /* From Uiverse.io by cssbuttons-io */
    button {
        margin: 0 auto;
        position: relative;
        border: none;
        background: transparent;
        padding: 0;
        cursor: pointer;
        outline-offset: 4px;
        transition: filter 250ms;
        user-select: none;
        touch-action: manipulation;
    }

    .shadow {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        background: #B59376;
        will-change: transform;
        transform: translateY(2px);
        transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
    }

    .edge {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        background: #B59376;
    }

    .front {
        display: block;
        position: relative;
        padding: 11px 80px;
        border-radius: 10px;
        font-size: 1.1rem;
        color: white;
        background: #DBCCBF;
        will-change: transform;
        transform: translateY(-4px);
        transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
    }

    button:hover {
        filter: brightness(110%);
    }

    button:hover .front {
        transform: translateY(-6px);
        transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
    }

    button:active .front {
        transform: translateY(-2px);
        transition: transform 34ms;
    }

    button:hover .shadow {
        transform: translateY(4px);
        transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
    }

    button:active .shadow {
        transform: translateY(1px);
        transition: transform 34ms;
    }

    button:focus:not(:focus-visible) {
        outline: none;
    }
}
</style>