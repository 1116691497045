//代币列表
export const BNBlist = [
  {
      "chainId": 56,
      "address": "0x55d398326f99059fF775485246999027B3197955",
      "name": "USDT",
      "symbol": "USDT",
      "decimals": 18,
      "logoURI": require('@/assets/img/logo/USDT.png')
    },
    {
      "chainId": 56,
      "address": "0x024a9ad0ecfaa8b3566d3310affb358379a55e7a",
      "name": "RAC",
      "symbol": "RAC",
      "decimals": 18,
      "logoURI": require('@/assets/img/logo/RAC.png')
    },
    {
      "chainId": 56,
      "address": "0xfa7e034dc83a50b67b4a0b421eb6a926a13b685f",
      "name": "Hamster Run Game",
      "symbol": "Hamster",
      "decimals": 18,
      "logoURI": require('@/assets/img/logo/Hamster.png')
    },
  
]



