import { render, staticRenderFns } from "./Remove.vue?vue&type=template&id=13dc4f95&scoped=true&"
import script from "./Remove.vue?vue&type=script&lang=js&"
export * from "./Remove.vue?vue&type=script&lang=js&"
import style0 from "./Remove.vue?vue&type=style&index=0&id=13dc4f95&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13dc4f95",
  null
  
)

export default component.exports