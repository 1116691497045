<template>
    <div class="snowballmain">
        <div class="box">
             
            <router-view />
        </div>
    </div>
</template>
<script>

export default {
    name: "Home",
    data() {
        return {
      
        };
    },
    computed:{
     
    },
    mounted() {
    },
    methods: {
      
    },
    components: { }
};
</script>
  
<style lang="less" scoped>
.snowballmain {
    width: 100%;
    background: #fff;
    // padding: 20px;
  height: 100%;
   margin-top: 50px;
}



</style>
  